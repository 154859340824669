/**
  textutils handles text operations on elements and nodes

  We use the W3C annotation TextQuoteSelector to anchor annotations to text
  on a webpage. This standard uses a prefix, selection (called 'exact') and
  suffix to find the annotation position in the document body. 

  This library deals with the two distinct types of text formatting present
  on a webpage: text formatting (spaces, punctuation, newlines, etc) and 
  tag formatting (p, br, li tags). For the TextQuoteSelector we only care 
  about text formatting - tags are completely ignored. However when 
  reproducing a selection and the sentence containing a selection tags should
  be preserved. Tags are also relavant to splitting sentences, for example 
  each item in a list is treated as a separate sentence. 

  Finally we recognize and ignore text ornaments that add characters.
  These numbers and their punctuation (usually formatted [1])
  must be recognized and ignored to preserve the integrity of other 
  annotations TextQuoteSelectors. 
**/
import rangy from 'rangy'
import domtoimage from 'dom-to-image'
import urlRegex from 'url-regex'
import yellow from '@mui/material/colors/yellow'
import lime from '@mui/material/colors/lime'
import sbd from 'sbd'
import merge from 'deepmerge'

const EXCLUDE = [
  'spirited-character', 
  'editor-embed', 
  'editor-image-group', 
  'style-tag',
  'paragraph-annotation',
  'epub-type-noteref'
];

// Number of characters on either side of a selection to use as the 
// prefix and suffix
const ADJACENT_LENGTH = 30

export const COMMENT_CHAR_LIMIT = 280

export const QUOTE_CHAR_LIMIT = 1000

export function stripWhitespace(str) {
  return str.replace(/^\s+|\s+$/gm,'')
}

export function getRangySelection() {
  return rangy.getSelection()
}

// export function legalTextNode(node) {
//   if (node.nodeType !== 3) {
//     return false;
//   } else { 
//     for (var i = 0; i < EXCLUDE.length; i++) {
//       if (typeof node.parentNode.className === 'string'
//         && node.parentNode.className.indexOf(EXCLUDE[i]) !== -1) {
//         return false;
//       }
//     } 
//   }
//   return true;
// }

export function legalTextNode(node) {
  if (node.nodeType !== 3) {
    return false;
  } else { 
    let myNode = node
    while (myNode.parentNode) {
      for (var i = 0; i < EXCLUDE.length; i++) {
        if (typeof myNode.parentNode.className === 'string'
          && myNode.parentNode.className.indexOf(EXCLUDE[i]) !== -1) {
          return false;
        }
      }
      myNode = myNode.parentNode
    }
  }
  return true;
}

/**
  Get an array of text nodes contained in an element
  Filter out nodes with spirited classes
**/
export function getTextNodes(el) {
  // rangy.init is needed in cases when a rangy function is called before the 
  // page has finished loading
  rangy.init()
  let range = rangy.createRange()
  range.selectNode(el)
  let nodes = range.getNodes([3])
  return nodes.filter(legalTextNode)
}

// Get the full text of the article by combining all text nodes
export function getFullText(textNodes) {
  let fullText = '';
  for (let i = 0; i < textNodes.length; i++) {
    fullText = fullText + textNodes[i].nodeValue;
  }

  // // NOTE Replace non-breaking spaces
  // fullText = fullText.replace(/\s/g,'')

  return fullText;
}

export function fullText(el) {
  return getFullText(getTextNodes(el));
}

/** 
  Get the raw text of a selection excluding spirited-characters
  
  NOTE
  Some weirdness with Rangy range offset values, the following is my
  best guess as to how they work:
  - startOffset applies to the first text node, not first node
  - endOffset applies to the last node of any type. Often, but not always,
    this is a text node
  Seems to have something to do with the fact that the start node can include
  all the parent nodes of the text node, but the end node is usually a text
  node.

  UPDATE
  The confusion came from getNodes, which returns "all the nodes partially 
  or wholly contained within the range" which can include the parents 
  of the startContainer or endContainer. startContainer is not necessarily 
  the first node in the array returned from getNodes.
**/
export function getSelText(sel) {
  let range = sel.getAllRanges()[0];
  return nodeText(range);
}

export function nodeText(range) {
  let nodes = range.getNodes([3],legalTextNode);

  // Loop through the nodes in the range extracting text
  let text = '';
  for (var i = 0; i < nodes.length; i++) {
    let val = nodes[i].nodeValue;
    if (nodes[i] === range.startContainer 
      && nodes[i] === range.endContainer) {
      text = val.slice(range.startOffset,range.endOffset);
    } else if (nodes[i] === range.startContainer) {
      text = text + val.slice(range.startOffset,val.length);
    } else if (nodes[i] === range.endContainer) {
      text = text + val.slice(0,range.endOffset);
    } else {
      text = text + val;
    }
  }

  return text;
}


/** 
  Get the index of a selection within the full text of an article
  
  Do it this way (instead of using indexOf) because of repeat words. You 
  would have to use adjacent text. 
**/
// TODO If startContainer is a spirited-character you have to get the
// next non-spirited-character node
export function articleIndex(el, sel) {
  let range = sel.getAllRanges()[0];
  let startNode = range.startContainer;
  let textNodes = getTextNodes(el);
  let idx = 0;

  for (let i = 0; i < textNodes.length; i++) {
    if (startNode === textNodes[i]) {
      return idx + range.startOffset;
    } else {
      idx = idx + textNodes[i].nodeValue.length;
    }
  }

    return idx;
}

/** 
  Get a range from the article based on start and end index in the 
  full text of the article
  start - the index of the first character
  end - index of the character after the last character (exclusive)
**/
// TODO Build a copy of articleText, compare it, warn if they don't match
export function getArticleRange(el, start, end) {
  let startNode, endNode, startOffset, endOffset = null
  let textNodes = getTextNodes(el)
  let articleText = getFullText(textNodes)
  let i = 0
  let position = 0

        
  // NOTE Changed 10/23/2020 adding the last two conditions to the while loop
  // (In case this breaks something)
  // In the previous version the loop would continue even after the 
  // start and end nodes were found, and they could be overwritten in 
  // rare cases
  while(position < articleText.length && !(startNode && endNode)) {
                
    if (position <= start && 
      (textNodes[i].nodeValue.length + position) >= start) {
      startNode = textNodes[i]
      startOffset = start - position
          }

    if (position <= end && 
      (textNodes[i].nodeValue.length + position) >= end) {
      endNode = textNodes[i]
      endOffset = end - position
                }

    position = position + textNodes[i].nodeValue.length
    i++
                      }

  
  // Build range out of nodes and offsets
  let range = rangy.createRange();
  range.setStart(startNode, startOffset);
  range.setEnd(endNode, endOffset);

  return range
}

// Get text for the selection and the prefix and suffix of the selection
export function getAdjacentText(el,sel,adjLen) {
  let exact = getSelText(sel);
  let text = fullText(el);
  let selStart = articleIndex(el,sel);
  let selEnd = selStart + exact.length;

  let prefixStart = (selStart - adjLen >= 0) ? (selStart - adjLen) : 0;

  let suffixEnd;
  if (selEnd + adjLen > text.length) {
    suffixEnd = text.length;
  } else {
    suffixEnd = selEnd + adjLen;
  }

  let prefix = text.slice(prefixStart,selStart);
  let suffix = text.slice(selEnd,suffixEnd);

        
  let obj = {
    exact: exact,
    prefix: prefix,
    suffix: suffix
  }   
  
  return obj; 
}

export function wordBounds(str,side) {
  let regexStr;

  // > 0 for word starts, else word ends
  if (side > 0) {
    regexStr = '[\n\r .?!](?=[A-Za-z0-9$“])';
  } else {
    regexStr = '[A-Za-z0-9](?=[\n\r .?!,"”])';
  }
  
  let regex = RegExp(regexStr,'g');
  let inidicies = [0]; // Regex doesn't account for first letter.

  while (regex.exec(str) !== null) {
    inidicies.push(regex.lastIndex);
  }

  return inidicies;
}

// The length of the text contained in a node
export function nodeTextLen(node) {
  let textLen = 0;
  if (legalTextNode(node)) {
    textLen = textLen + node.nodeValue.length;
  } else if (node.childNodes.length > 0) {
    for (var i = 0; i < node.childNodes.length; i++) {
      textLen = textLen + nodeTextLen(node.childNodes[i]);
    }
  }

  return textLen;
}

/**
  Find indicies in the full text of the article for tags that are to be 
  interpretted as sentence breaks. 
**/
export function tagBreaks(el) {
  let newLineBefore = ['UL','H1','H2','H3']
  let newLineAfter  = ['P','LI','H1','H2','H3','BR','DIV']
  let elements = newLineBefore.concat(newLineAfter)
  /**
    Loop through the entire node tree structure (flattened by getNodes)
    When it finds a node that is of the break type get the length of the
    text contained inside and add the text position. Append this value to
    the tagBreaks array.
    The break is positioned after the text inside the node. 
  **/
  let range = rangy.createRange()
  range.selectNode(el)
  let nodes = range.getNodes()
  let position = 0
  let tagBreaks = []

  for (var i = 0; i < nodes.length; i++) {
    if (legalTextNode(nodes[i])) {
      position = position + nodes[i].nodeValue.length
    // If this is any of the tags we add breaks for
    } else if (elements.indexOf(nodes[i].nodeName) >= 0) {
      if (newLineBefore.indexOf(nodes[i].nodeName) >= 0) {
        tagBreaks.push(position - 1)
      }
      if (newLineAfter.indexOf(nodes[i].nodeName) >= 0) {
        tagBreaks.push(position + nodeTextLen(nodes[i]))
      }
    }
  }
  //console.log('tagBreaks: ',tagBreaks);
  return tagBreaks
}

/**
  Use word bounds to snap to closest word on either side of a selection
  TODO Fix bug where it gets words before spaces, or words before the first
  word of a paragraph
*/
export function snapToWord(el,sel) {
    let selText = getSelText(sel)
    let start = articleIndex(el,sel)
  let end = start + selText.length
  // If the first or last character is a space snap the opposite dir
  // TODO This only handles single spaces...
  start = (selText.charAt(0) === ' ' ? start + 1 : start);
  end = (selText.charAt(selText.length-1)) === ' ' ? end - 1 : end;
  
  let text = fullText(el);

  let wordStarts = wordBounds(text,1);
  let wordEnds = wordBounds(text,-1);
  let tBreaks = tagBreaks(el);

  wordStarts = wordStarts.concat(tBreaks);
  wordEnds = wordEnds.concat(tBreaks);

  let newStart = Math.max(...wordStarts.filter(val => val <= start));
  let newEnd = Math.min(...wordEnds.filter(val => val >= end));
  
  let range = getArticleRange(el,newStart,newEnd)
    sel.setSingleRange(range);
}

/** 
  The indicies in the article text of sentence boundries based on 
  punctuation.
**/
// TODO Use this instead https://public.oed.com/how-to-use-the-oed/abbreviations/
export function sentenceBounds(str) {
  let government = ['A.A','abs','acct','A.D','a.k.a','A.L.R',
  'A.M','M.A','a.m','approx','A.S.N','Atl','Ave','B.A','A.B','B.C','B.C.E',
  'B.C.E','bf','Bldg','B.Lit','B.Litt','Blvd','b.o','B.S','B.Sc','ca',
  'C.C.A','C.Cls','C.Cls.R','C.C.P.A','C.E','cf','CFR Supp','C.J','Co',
  'c.o.d','Comp. Dec','Comp. Gen','con','Corp','c.p','C.P.A','cr','Ct',
  'Cir','Dall','d.b.a','d.b.h','D.D','D.D.S','Dist. Ct','do','D.P.H',
  'D.P.Hy','dr','Dr','d.s.t','D.V.M','E','e.g','e.o.m','et al','et seq',
  'etc','Ex. Doc','f','ff','f.a.s','Fed','f.o.b','G.M.&S','Gov','gr. wt',
  'H.C','H. Con. Res','H. Doc','H.J. Res','How','H.R','H. Rept','H. Res',
  'ibid','id','i.e','Insp. Gen','J.D','Jpn','Jr','Judge Adv. Gen','lat',
  'lc','L.Ed','liq','lf','LL.B','LL.D','loc. cit','long','Ltd','Lt. Gov',
  'M','MM','m','M.D','Misc','Doc','Mlle','Mme','Mmes','mo','M.P','Mr','Ms',
  'M.S','MS','Msgr','m.s.l','N','NACo','NE','n.e.c','n.e.s','net wt',
  'N.F','n.l','No','n.o.i.b.n','n.o.p','n.o.s','n.s.k','n.s.p.f','NW',
  'Op. Atty. Gen','op. cit','Pac','Pet','Phar.D','Ph.B','B.Ph','Ph.D',
  'D.Ph','Ph.G','Pl','p.m','P.O','P.S','Rd','Rev','Rev. Stat','R.F.D',
  'R.N','RR','Rt. Rev','Ry','S','sc','s.c','S. Con. Res','s.d','S. Doc',
  'SE','S.J. Res','sp. gr','Sq','Sr','S. Rept','S. Res','St','Stat',
  'Sup. Ct','Supp. Rev. Stat','Supt','Surg','Surg. Gen','SW','S.W.2d',
  'T','T.D','Ter','t.m','U.N','U.S','U.S.A','U.S.C','U.S.C.A','U.S.C. Supp',
  'U.S.P','U.S.S','v','vs','W','w.a.e','Wall','Wheat','w.o.p','bd. ft'];

  // TODO Mt not found above. Some other class of abbreviation?
  let landmarks = ['Mt']

  // TODO Military ranks aren't in the document?!
  
  // TODO Finish this very long list at some point...
  let latin = ['a','A.A.C','A.A.S','A.B','ab init','abs. re','A.C','a.d',
  'ad fin','ad h.l','ad inf','ad init','ad int','ad lib','ad loc',
  'ad val','A.I','al','A.M']

  // Every letter can be a middle initial
  // Not sure what to do about `I`
  let initials = [' A',' B',' C',' D',' E',' F',' G',' H',' I',' J',' K',' L',
  ' M',' N',' O',' P',' Q',' R',' S',' T',' U',' V',' W',' X',' Y',' Z']

  // TODO What about H.G. Wells?

  // The sentence bountries in the string
  let exceptions = government.concat(landmarks).concat(latin).concat(initials)
  
  /**
    This Regex in English: 
    (?<!${exceptions.join('|')}) - Does not begin with an item in the 
    list of exceptions

    [.!?] - Has a punctuation mark
    [ ’”]? - optional space or end quotes
    (?=[A-Z]) - followed by a capital letter
    |\n|\r - always count newlines as sentence boundries
  **/
  //let regexStr = `(?<!${exceptions.join('|')})[.!?][ ]?(?=[A-Z])|\n|\r`;
  let regexStr = '[.!?]+[ ’”]?(?=[“A-Z])|\n|\r';
  let regex = RegExp(regexStr,'g');
  let inidicies = [0];

  while (regex.exec(str) !== null) {
    inidicies.push(regex.lastIndex);
  }

  regexStr = `[ .](${exceptions.join('|')})\\. `;
  regex = RegExp(regexStr,'g');

  let removals = [];

  while (regex.exec(str) !== null) {
    removals.push(regex.lastIndex);
  }

  return inidicies.filter(x => !(removals.indexOf(x) >= 0));
}

export function sentenceArray(el) {
  const text = fullText(el)

  let textIndicies = sentenceBounds(text)
  let tagIndicies = tagBreaks(el)
  let indicies = [...new Set(textIndicies.concat(tagIndicies))]
  indicies.sort((a,b) => a - b)

  const arr = []
  for (var i = 0; i < indicies.length - 1; i++) {
    arr.push(text.substring(indicies[i],indicies[i+1]))
  } 

  return arr
}

// Use getArticleText to create an array of sentences
export function selectedSentences(el,sel) {
  // Remove spirited-characters
  const container = document.createElement('div')
  container.innerHTML = el.innerHTML
  container.querySelectorAll('.epub-type-noteref').forEach(node => {
    node.remove()
  })

  // let html = el.innerHTML// .replace(/\[[0-9,]+\]/g, '')
  let html = container.innerHTML

  /*
    Previous settings:

    {
      "newline_boundaries"  : false,
      "html_boundaries"     : true,
      "sanitize"            : true,
      "allowed_tags"        : false,
      "preserve_whitespace" : false,
      "abbreviations"       : null
    }

  */


  let mySentences = sbd.sentences(html, {
    "newline_boundaries"  : true,
    "html_boundaries"     : true,
    "html_boundaries_tags": ['h1', 'h2', 'h3', 'h4', 'h5', 'p'],
    "sanitize"            : true,
    "allowed_tags"        : false,
    "preserve_whitespace" : false,
    "abbreviations"       : null
  })

  // console.log('mySentences', mySentences)

  // mySentences = mySentences.map(s => s.replace(/\[[0-9,]+\]/g, ''))

  let articleText = fullText(el).replace(/\s/g,' ');

  let indicies = []
  let map = {}
  for (var s of mySentences) {
    // NOTE Added .trim() to fix a bug with books where "\n " was added to 
    // the beginning of some lines. Does not appear to break anything.
    let idx = articleText.indexOf(s.replace(/\s/g,' ').trim())
    if (idx >= 0) {
      indicies.push(idx)
      map[idx] = s
    } 
  }

  // Final index is the end of the last sentence
  indicies.push(articleText.length)

    
  
  // for (var i = 0; i < mySentences[0].length; i++) {
  //   if (mySentences[0].charCodeAt(i) !== articleText.charCodeAt(i)) {
  //     console.log('diff at ',i)
  //     console.log(`"${mySentences[0].charCodeAt(i)}" vs "${articleText.charCodeAt(i)}"`)
  //   }
  // }

  // let textIndicies = sentenceBounds(articleText)
  // let tagIndicies = tagBreaks(el)
  // let indicies = textIndicies.concat(tagIndicies)
  // console.log('indicies', indicies)

  let start = articleIndex(el, sel)
  let end = start + getSelText(sel).length

  console.log('start',start,'end',end)

  let senStart = Math.max(...indicies.filter(val => val <= start))
  let senEnd = Math.min(...indicies.filter(val => val >= end))

  // TODO Indicies 
  if (senStart === -Infinity) {
    senStart = 0
  }
  if (senEnd === Infinity) {
    senEnd = articleText.length
  }

  console.log('senStart', senStart, 'senEnd', senEnd)

  // The range containing all the sentences together
  let sentences = getArticleRange(el, senStart, senEnd)
  
  let sentenceArray = []
  for (var idx of Object.keys(map)) {
    if (idx >= senStart && idx <= senEnd) {
      sentenceArray.push(map[idx])
    }
  }

  console.log('sentenceArray', sentenceArray)

  // // An array of the text of each sentence
  // let sentenceBreaks = indicies.filter(i => i >= senStart && i <= senEnd)
  // sentenceBreaks.sort((a,b) => {return a - b})

  // let sentenceArray = []
  // if (sentenceBreaks.length > 2) {
  //   for(var i = 0; i < sentenceBreaks.length - 1; i++) {
  //     let sentence = getArticleRange(el,sentenceBreaks[i],sentenceBreaks[i+1])
  //     let senText = nodeText(sentence)
  //     if (senText.match(/[a-z0-9]/gi) && senText.length > 0) {
  //       sentenceArray.push(senText)
  //     }
  //   }
  // } else {
  //   let senText = nodeText(sentences)
  //   if (senText.match(/[a-z0-9]/gi) && senText.length > 0) {
  //     sentenceArray.push(senText)
  //   }
  // }

  return {sentencesRange: sentences, sentenceArray: sentenceArray}
}

// The index (beginning) of a full annotation in an element
export function annotationIndex(el, prefix, exact, suffix) {
  let articleText = fullText(el)// .replace(/\s/g,'')
    let fullString = (prefix + exact + suffix)// .replace(/\s/g,'')
    let index = articleText.indexOf(fullString)
  if (index >= 0) {
    return index + prefix.length
  } else {
    return false
  }
}

// Get the range of the annotation in the article
export function annotationRange(el, prefix, exact, suffix) {
  let start = annotationIndex(el, prefix, exact, suffix);
  let end = exact.length + start;
    if (!Number.isInteger(start) || !Number.isInteger(end)) {
    return false
  }

  return getArticleRange(el,start,end)
}

// Add a span to a range of text with the specified className
export function formatText(range, className) {
  const highlights = []

  // canSurroundContents is false if an incomplete tag is selected
  if (!range.canSurroundContents()) {
        // Surround each text node individually
    // NOTE even surround EXCLUDE characters
    let nodes = range.getNodes([3]);
        for (var i = 0; i < nodes.length; i++) {
            let partialRange = rangy.createRange()
      if (i === 0) {
        // The beginning of the selection
        partialRange.setStart(nodes[i], range.startOffset)
        partialRange.setEnd(nodes[i], nodes[i].nodeValue.length)
      } else if (i === nodes.length - 1) {
        // The end of the selection
        partialRange.setStart(nodes[i], 0)
        partialRange.setEnd(nodes[i], range.endOffset)
        // partialRange.setEnd(nodes[i], nodes[i].nodeValue.length)
      } else {
        // Any node in the middle of the selection
        partialRange.selectNode(nodes[i])
      }

            let highlight = document.createElement('span')
      highlight.setAttribute('class', className)
            partialRange.surroundContents(highlight)
      highlights.push(highlight)
    }
  } else {
        let highlight = document.createElement('span')
    highlight.setAttribute('class', className)
    range.surroundContents(highlight)
    highlights.push(highlight)
  }

    return highlights
}

// // TODO
// // You may need to rebuild the range
// // NOTE This is only used for HTML viewing
// export function stripOrnaments(range) {
//   for (var i = 0; i < range.childNodes.length; i++) {
//     if (range.childNodes[i].nodeName === 'spirited-character') {
//       range.childNodes[i].parentNode.removeChild(range.childNodes[i]);
//     } else if (range.childNodes[i].nodeName === 'highlight') {
//       range.childNodes[i].outerHTML = range.childNodes[i].innerHTML;
//     }

//     if (range.childNodes[i].childNodes.length > 0) {
//       stripOrnaments(range.childNodes[i]);
//     }  
//   }
// }

// Hack add spaces on HTML breaks, remove ornaments
export function badFixText(string) {
  let regex = /[.?!](?=[^\s])/;
  string = string.replace(regex,'. ');

  let regex2 = /\[\d\]|\*/;
  string = string.replace(regex2,'');

  return string;
}

export function buildWordMap(annotation) {
  let ele = document.createElement('div')
  ele.style.display = 'none'
  ele.innerHTML = annotation.sentenceHTML
  document.body.appendChild(ele)

  let nodes = getTextNodes(ele)
  let obj = {}
  obj['words'] = []

  let sentences = annotation.sentenceArray.join('')
  let selStart = sentences.indexOf(annotation.target.selector.exact)
  let selEnd = selStart + annotation.target.selector.exact.length

  // We will rebuild the text of the sentences to compare to selStart and 
  // selEnd
  let sentenceText = ''
  for (var i = 0; i < nodes.length; i++) {
    // Loop through the nodes in the sentences range extracting text

    // All the formats surrounding this node
    let formats = nodeFormats(ele,nodes[i])

    // Get words of text
    let words = nodes[i].textContent.split(" ")
    
    // Record each word with it's formatting
    for (var j = 0; j < words.length; j++) {
      let wordObj

      if (words[j].length > 0) {
        wordObj = {
          'value': words[j], 
          'newline': false,
          'formats': formats, 
        }
      }

      // Words are split on spaces, but snapToWord snaps to alphanumeric 
      // characters. When finding if a word is in the selection exclude
      // non-alphanumeric characters.
      if (wordObj 
        && (sentenceText + words[j]).length >= selStart 
        && (sentenceText + words[j]).length <= selEnd) {
        wordObj['inSelection'] = true
              } else {
              }
            
      if (wordObj) {
        obj['words'].push(wordObj)
      }

      if (words[j].length > 0) {
        sentenceText += words[j] + ' '
      }
    }

    // if this node is a break node insert a newline after the words
    if (formats['breakAfter']) {
      obj['words'].push({
        'value': null, 
        'newline': true,
        'formats': {
          'bold': false,
          'italic': false,
          'underrline': false
        }
      })
    }
  }

  ele.parentNode.removeChild(ele)

  return obj
}

/**
  Convert a range into a JSON object containing words and any text-specific
  formatting to be applied to each word.

  For each text node check every successive parentNode for relavant formatting
  until you reach the articleElement. Record each formatting associated with
  each word.

  Ignore text contained in spirited-characters.

  NOTE nodeText does exactly this but we need to go to the parent, so there is
  duplicate code (for now)
*/
export function getWordMap(articleElement,selection,sentences) {
          let nodes = sentences.getNodes([3],legalTextNode)
  let obj = {}
  obj['words'] = []

  // Find the index of the selection in the text of the sentences
  let senText = nodeText(sentences)
  let selText = nodeText(selection)
    let selStart = senText.indexOf(selText)
  let selEnd = selStart + selText.length
    
  // We will rebuild the text of the sentences to compare to selStart and 
  // selEnd
  let sentenceText = ''
  for (var i = 0; i < nodes.length; i++) {
    // Loop through the nodes in the sentences range extracting text
    let text = '';
    let val = nodes[i].nodeValue;
    if (nodes[i] === sentences.startContainer 
      && nodes[i] === sentences.endContainer) {
      //console.log('getSelText entire node: ', textNodes[i]);
      text = val.slice(sentences.startOffset,sentences.endOffset)
    } else if (nodes[i] === sentences.startContainer) {
      //console.log('getSelText start text node',textNodes[i]);
      text = text + val.slice(sentences.startOffset,val.length)
    } else if (nodes[i] === sentences.endContainer) {
      //console.log('getSelText end text node',textNodes[i]);
      text = text + val.slice(0,sentences.endOffset)
    } else {
      //console.log('getSelText middle text node');
      text = text + val
    }


    // All the formats surrounding this node
    let formats = nodeFormats(articleElement,nodes[i])

    // Get words of text
    let words = text.split(" ")
    
    // Record each word with it's formatting
    for (var j = 0; j < words.length; j++) {
      let wordObj

      if (words[j].length > 0) {
        wordObj = {
          'value': words[j], 
          'newline': false,
          'formats': formats, 
        }
      }

      // Words are split on spaces, but snapToWord snaps to alphanumeric 
      // characters. When finding if a word is in the selection exclude
      // non-alphanumeric characters.
      if (wordObj 
        && (sentenceText + words[j].replace(/\W/g, '')).length >= selStart 
        && (sentenceText + words[j].replace(/\W/g, '')).length <= selEnd) {
        wordObj['inSelection'] = true
              } else {
              }
            
      if (wordObj) {
        obj['words'].push(wordObj)
      }

      if (words[j].length > 0) {
        sentenceText += words[j] + ' '
      }
    }

    // if this node is a break node insert a newline after the words
    if (formats['breakAfter']) {
      obj['words'].push({
        'value': null, 
        'newline': true,
        'formats': {
          'bold': false,
          'italic': false,
          'underrline': false
        }
      })
    }


  }
  return obj
}

// Check every parent up to the articleElement for the relavant types
export function nodeFormats(articleElement,node) {
  let obj = {}
  obj['bold'] = false
  obj['italic'] = false
  obj['underline'] = false
  let parent = node.parentNode

  // TODO Failsafe? parentNode exists?
  while (parent !== articleElement) {
    obj = elementFormat(parent.nodeName,obj)

    // If this is a breakNode check if the node (originally passed) 
    // is the last text child node in this breakNode. If it is add 
    // breakAfter to obj
    if (isBreakNode(parent)) {
      if (node === lastTextChild(parent)) {
                obj['breakAfter'] = true
      }
    }

    parent = parent.parentNode;
  }
    return obj
}

// TODO Look at CSS for elements
export function elementFormat(nodeName,obj) {
  if (nodeName === 'B' || nodeName === 'STRONG') {
    obj['bold'] = true
  } else if (nodeName === 'I' || nodeName === 'EM') {
    obj['italic'] = true
  } else if (nodeName === 'U') {
    obj['underline'] = true
  } 

  return obj
}

export function isBreakNode(node) {
  return node.nodeName === 'P' || node.nodeName === 'LI';
}

export function lastTextChild(node) {
  let range = rangy.createRange();
  range.selectNode(node);
  let textNodes = range.getNodes([3]);
  return textNodes[textNodes.length - 1];
}

/**
  Re-write Node.contains because IE does not support it, and Safari's 
  implementation is broken
  https://developer.mozilla.org/en-US/docs/Web/API/Node/contains
*/
export function nodeContains(node,target) {
  let children = node.childNodes;

  for (var i = 0; i < children.length; i++) {
    if (children[i] === target) {
      return true;
    } else if (children[i].childNodes.length > 0) {
      if (nodeContains(children[i],target)) {
        return true;
      }
    }
  }

  return false;
}

/**
  Check if a selection
  - is completely contained in the article element
  - does not contain anything in the tray
  - contains at least 1 alphanumeric character (no whitespace annotations)
*/
// TODO If first or last character is a spirited-character this is false
export function legalRange(sel,articleElement,trayElement) {
  let range = sel.getAllRanges()[0]

  let selText = getSelText(sel)

  return nodeContains(articleElement,range.startContainer) &&
    nodeContains(articleElement,range.endContainer) &&
    !nodeContains(trayElement,range.startContainer) &&
    !nodeContains(trayElement,range.endContainer) &&
    selText.match(/[a-zA-z0-9]/i)
}

// Extract information from a text selection that is used in an annotation
export function selectionData(articleElement, sel) {

  // Get the range(s) of the current selection
  let range = sel.getAllRanges()[0]

  // The prefix, exact and suffix portions of a selector
  let selector = getAdjacentText(articleElement, sel, ADJACENT_LENGTH)

  let {sentencesRange, sentenceArray} = selectedSentences(articleElement, sel)
  
  // The Rangy function toHtml() bypasses our legalTextRange filter
  // so spirited-characters are included
  let sentenceHTML = sentencesRange.toHtml()
  console.log('sentenceHTML', sentenceHTML);

  console.log('sentencesRange.toString()', sentencesRange.toString())

  let data = {
    selection: {
      rect: range.nativeRange.getBoundingClientRect(),
      target: {
        selector: selector
      },
      sentenceHTML: sentenceHTML,
      sentenceArray: sentenceArray
    }
  }

  return data  
}

// const GOOGLE_FONTS_API_KEY = 'AIzaSyBiFrCPb0R5zTnrj4nekhRUQOEwkouH2sw'

function errorHandler(e) {
  console.warn('ERR', e)
}

export function fetchCSS(family, variant) {
  let url = 'https://fonts.googleapis.com/css?family=' 
   + family.replace(/ /g, '+')

   // Get bold, italic and bold italic
   url += ':400,400i,700,700i'

   // TODO add variant to the URL
   if (variant) {
    url += (':' + variant)
   }
    return fetch(url).then(res => {
    return res.text()
  }, errorHandler)
}

// export const old_urlRegex = new RegExp(/(https|http)?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g)
// Added commas to this because Wikipedia puts commas in urls (??)
// export const urlRegex = new RegExp(/(https|http)?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.,~#?&//=]*)/g)

export const mentionRegex = new RegExp(/@[a-zA-Z0-9]+/g)
export const hashtagRegex = new RegExp(/#[a-zA-Z0-9]+/g)

// Return the first link found in a string
export function detectLinks(value) {
  // let exp = /(https|http)?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g
  // let exp = /(https|http)?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g
  //let regex = new RegExp(urlRegex)
  let matches = [...value.matchAll(urlRegex({exact: false, strict: false}))].map(match => match[0])
  console.log('matches', matches)
  if (matches && matches.length > 0) {
    return matches
  } else if (value.includes('http://localhost:3000/')) {
    // TODO Special exception for development
    return [value]
  } else {
    return null
  }
}

export const spiritedRegex = new RegExp(window.location.host + '/(comment|target|article|book)/[A-Za-z0-9-]+', 'g')

export const anySpiritedRegex = new RegExp(window.location.host + '/[A-Za-z0-9-/]+', 'g')

export function anySpiritedRoute(path, routeMap) {
  for (var route of routeMap) {
    let start = route.path.split(':')[0]
    if (path.startsWith(start)) {
      return true
    }
  }

  return false
}

export function detectSpiritedLink(value, routeMap) {
  // let regex = new RegExp(spiritedRegex)
  // let urls = value.match(spiritedRegex)
    // if (urls.length > 0) {
  let obj = {url: value}
  let host = window.location.host
  console.log('value',value)
  console.log('host', host)
  if (value.startsWith(`http://${host}`) 
    || value.startsWith(`https://${host}`)
    || value.startsWith(host)) {
    console.log('value starts with host')
    let path = value.split(host)[1]
    if (path.startsWith('/responses/')) {
      obj['boostTargetId'] = path.replace('/responses/','')
      return obj
    } else if (path.startsWith('/article/')) {
      obj['articleId'] = path.replace('/article/','')
      return obj
    } else if (path.startsWith('/book/')) {
      obj['bookId'] = path.replace('/book/','')
      return obj
    } else if (anySpiritedRoute(path, routeMap)) {
      // Links to other parts of the Spirited app cannot be responded to
      obj['error'] = "You can't respond to that page"
      return obj
    } 
    // else {
    //   // If this isn't some other Spirited route, guess that it is an 
    //   // alias. Alias routes are structured: spirited.net/aliasName
    //   // NOTE It's possible the alias doesn't exist, that has to be handled 
    //   // by the caller of this function.
    //   obj['aliasId'] = path.replace('/','')
    //   return obj
    // }
  }
  // }

  return false
}

export function extractSpiritedDocument(value) {
  let obj = {}
  let host = window.location.host
  console.log('value', value)
  if (value.startsWith(host)) {
    let route = value.replace(host, '')
    if (route.startsWith('/comment/')) {
      obj['commentId'] = route.replace('/comment/','')
      return obj
    } else if (route.startsWith('/responses/')) {
      obj['boostTargetId'] = route.replace('/responses/','')
      return obj
    } else if (route.startsWith('/article/')) {
      obj['articleId'] = route.replace('/article/','')
      return obj
    } else if (route.startsWith('/book/')) {
      obj['bookId'] = route.replace('/book/','')
      console.log('obj', obj)
      return obj
    } else {
      return obj
    }
  }
}

export function baseUrl(url) {
  if (url) {
    const regex = /^https?:\/\/[^/]+/g
    const found = url.match(regex)
    if (found.length > 0) {
      return found[0].replace('https://','')
    } 
  }
}

// export function closeQuotes(text, exact) {
//   // String copy bug workaround
//   let newText = (' ' + text).slice(1)
//   let newExact = (' ' + text).slice(1)
//   let shift = 0

//   // Replace double quotes with opening and closing quotes
//   // TODO This doesn't handle the case where there is a closing double
//   // quote without an opening quote (i.e. they took one sentence from a 
//   // multi-sentence quote). 
//   // That case can only be handled when the annotation is created. There's no
//   // way to know if a lone double quote comes from the beginning or end 
//   // of a multi-sentence quote, when you only have one sentence.
//   let quotes = 0
//   for (var i = 0; i < newText.length; i++) {
//     if (newText.charAt(i) === '"') {
//       if (quotes % 2 === 0) {
//         newText = newText.substring(0,i) + '“' + newText.substring(i+1)
//       } else {
//         newText = newText.substring(0,i) + '”' + newText.substring(i+1)
//       }
//       quotes++
//     }
//   }

//   let openings = (newText.match(/“/) || []).length
//   let closings = (newText.match(/”/) || []).length

//   // Step 1
//   // When there is an unclosed quote (the passage contains part of a quote) 
//   // close it
//   if (openings > closings) {
//     newText = newText + '”'
//   } else if (closings > openings) {
//     newText = '“' + newText
//     shift += 1
//   } 

//   // Step 2
//   // Wrap the entire passage in quotes, because we're quoting it, unless it
//   // is already a quote
//   // NOTE This should only happen if Step 1 didn't fire
//   if (!(newText.charAt(0) === '“' && newText.slice(-1) === '”')) {
//     newText = '“' + newText + '”'
//     shift += 1
//   }

//   // Fix double successive double-quotes ‘ ’ 
//   let open = 0
//   for (var j = 0; j < newText.length; j++) {
//     if (newText.charAt(j) === '“') {
//       open++

//       // Found a second opening quote
//       if (open % 2 === 0) {
//         newText = newText.substring(0, j) + '‘' + newText.substring(j + 1)
//       }
//     }

//     if (newText.charAt(j) === '”') {
//       if (open % 2 === 0) {
//         newText = newText.substring(0, j) + '’' + newText.substring(j + 1)
//       }

//       open--
//     }
//   }

//   return {quotedText: newText, shift: shift}
// }

export function closeQuotes(text, exact) {
  // String copy bug workaround
  let newText = (' ' + text).slice(1)
  let newExact = (' ' + exact).slice(1)
  // eslint-disable-next-line
  //let shift = 0
  let start = newText.indexOf(exact)
  let end = start + exact.length

  // Replace double quotes with opening and closing quotes
  // TODO This doesn't handle the case where there is a closing double
  // quote without an opening quote (i.e. they took one sentence from a 
  // multi-sentence quote). 
  // NOTE That case can only be handled when the annotation is created.
  // There's no way to know if a lone double quote comes from the beginning 
  // or end of a multi-sentence quote, when you only have one sentence.
  let quotes = 0
  for (var i = 0; i < newText.length; i++) {
    if (newText.charAt(i) === '"') {
      if (quotes % 2 === 0) {
        newText = newText.substring(0,i) + '“' + newText.substring(i+1)
        if (start <= i && end >= i) {
          newExact = newExact.substring(0,(i-start)) + '“' + newExact.substring((i-start) + 1)
        }
      } else {
        newText = newText.substring(0,i) + '”' + newText.substring(i+1)
        if (start <= i && end >= i) {
          newExact = newExact.substring(0,(i-start)) + '”' + newExact.substring((i-start) + 1)
        }
      }
      quotes++
    }
  }

  let openings = (newText.match(/“/) || []).length
  let closings = (newText.match(/”/) || []).length

  // Step 1
  // When there is an unclosed quote (the passage contains part of a quote) 
  // close it
  if (openings > closings) {
    newText = newText + '”'
  } else if (closings > openings) {
    newText = '“' + newText
    //shift += 1
  } 

  // Step 2
  // Wrap the entire passage in quotes, because we're quoting it, unless it
  // is already a quote
  // NOTE This should only happen if Step 1 didn't fire
  if (!(newText.charAt(0) === '“' && newText.slice(-1) === '”')) {
    newText = '“' + newText + '”'
    //shift += 1
  }

  // Fix double successive double-quotes ‘ ’ 
  let open = 0
  for (var j = 0; j < newText.length; j++) {
    if (newText.charAt(j) === '“') {
      open++

      // Found a second opening quote
      if (open % 2 === 0) {
        newText = newText.substring(0, j) + '‘' + newText.substring(j + 1)
        if (start <= j && end >= j) {
          newExact = newExact.substring(0, (j-start)) + '‘' + newExact.substring((j-start) + 1)
        }
      }
    }

    if (newText.charAt(j) === '”') {
      if (open % 2 === 0) {
        newText = newText.substring(0, j) + '’' + newText.substring(j + 1)
        if (start <= j && end >= j) {
          newExact = newExact.substring(0, (j-start)) + '’' + newExact.substring((j-start) + 1)
        }
      }

      open--
    }
  }

  return {quotedText: newText, quotedExact: newExact}
}

// Remove parameters from a URL unless it is on a whitelist
// NOTE This function exists in cloud functions as well, make sure to update 
// it there if you update it here.
export function cleanURL(url) {
  const dontClean = [
    'https://www.youtube.com/watch'
  ]
  
  let cleanUrl = url.split('?')[0]

  if (dontClean.includes(cleanUrl)) {
    return url
  } else {
    return cleanUrl
  }
}

export function spiritedUrl(url) {
  let possible = [
    'localhost:3000/article/',
    'spirited.net/article/',
    'spirited-prod.web.app/article/',
    'posttext.io/article/',
  ]

  for (var base of possible) {
    if (url.includes(base)) {
      const id = url.split('/article/')[1]

      return '/article/' + id
    }
  }

  return null
}

export function wordCount(text) {
  return text.split(' ').length
}

export function searchTerms(strings) {
  const terms = []
  const regex = /([A-Za-z])\w+/g
  for (var str of strings) {
    terms.concat(str.match(regex).map(w => w.toLowerCase()))
  }
}

export function dateString(timeStamp) {
  let postAgeValue
  let date = new Date()
  let secondsNow = date.getTime() / 1000
  let secondsPost = timeStamp.seconds
  let secondsAge = secondsNow - secondsPost
  if (secondsAge < 60) {
    postAgeValue = Math.round(secondsAge) + 's'
  } else if (secondsAge < 60*60) {
    postAgeValue = Math.round(secondsAge/60) + 'm'
  } else if (secondsAge < 60*60*24) {
    postAgeValue = Math.round(secondsAge/(60*60)) + 'h'
  } else {
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", 
      "Aug", "Sep", "Oct", "Nov", "Dec"]
    let d = new Date(1970, 0, 1)
    d.setTime(secondsPost * 1000)
    postAgeValue = monthNames[d.getMonth()] + ' ' + d.getDate()
  }

  return postAgeValue
}

export function cleanAuthorNames(authors) {
  if (!Array.isArray(authors)) {
    authors = [authors]
  }

  // Remove duplicates
  authors = [...new Set(authors)]

  let names = []

  for (var name of authors) {
    if (name.includes(',')) {
      let splitNames = name.split(',')
      names.push(`${splitNames[1]} ${splitNames[0]}`)
    } else {
      names.push(name)
    }
  }

  return names.join(', ')
}

export function cleanDate(dateStr) {
  let date = new Date(dateStr)

  return date.getFullYear()
}

export function dasherize(str) {
  return str.toLowerCase()
    // Normalize accent characters to their base character
    .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    .replace(/[`'|]+ */, '')
    .replace(/[^a-z0-9]/gi, ' ') // str.replace(/[,.;@#?!&$`'"|]+ */, ' ')
    .trim()
    .replace(/\s+/g,'-') // 1 or more spaces to a dash
}

// Create an id for the boost target based on a string (ideally the title 
// and author or site name)
export function createBtId(str) {
  return dasherize(str) + '-' + (new Date()).getTime();
}

export function createBookQuery(title, authors) {
  if (!title) {
    throw new Error('Title is required');
  }

  let str = title + ' ' + authors;

  return str.toLowerCase()
    .replace(/[`'’|]+ */, '')
    .replace(/[^a-z0-9]/gi, ' ') // if not alphanumeric replace with space
    .trim()
    .replace(/\s+/g,' ') // 1 or more spaces to a single space
}


export function unDash(string) {
  let words = string.split('-');
  let newWords = [];
  const special = ['us','usa'];

  for (var word of words) {
    if (special.includes(word)) {
      newWords.push(word.toUpperCase())
    } else {
      newWords.push(
        word.charAt(0).toUpperCase() + word.slice(1)
      )
    }
  }
  
  return newWords.join(' ');
}

function firstLast(str) {
  if (str.includes(', ')) {
    let names = str.split(', ')
    return names[1] + ' ' + names[0]
  } else {
    return str;
  }
}

export function getBtInfo(bt, projectId) {
  const info = {
    title: null,
    url: null,
    origin: null,
    originUrl: null,
    description: null,
    image: {}
  };

  if (bt.meta.site) {
    // Website
    info.title = bt.meta.title;
    info.url = bt.url;
    info.origin = bt.meta.site;
    info.description = bt.meta.description;
    info.image = bt.links.thumbnail[0];
  } else if (bt.meta.authors) {
    // Open library OR ISBNdb record
    info.title = bt.meta.title;
    info.url = `${window.location.origin}/responses/${bt.id}`;
    info.origin = bt.meta.authors.map(a => firstLast(a)).join(', ');
    info.description = bt.meta.description || bt.meta.synopsis;
    if (bt.meta.cover) {
      info.image.href = bt.meta.cover.medium || bt.meta.cover.small;
    } else if (bt.meta.image) {
      info.image.href = bt.meta.image;
    }
  } else if (bt.hosted) {
    // Hosted eBook book record
    info.title = bt.meta.title;
    info.url = `${window.location.origin}/responses/${bt.id}`;
    info.origin = bt.meta.author || bt.meta.creator;
    info.description = bt.meta.description;
    if (bt.meta.coverLocation) {
      info.image.href = urlOfLocation(bt.meta.coverLocation, projectId);
    }
  } else if (bt.meta.alias) {
    // PostText article
    info.title = bt.meta.title;
    info.url = `${window.location.origin}/${bt.route}`;
    info.origin = bt.meta.alias.displayName || bt.meta.alias.name;
    info.originUrl = `${window.location.origin}/${bt.meta.alias.name}`;
    info.description = bt.meta.description;
    info.image.href = bt.links.thumbnail[0]
  }
  

  return info;
}

/******************
 * NON-TEXT UTILS 
 ******************/

export const gold = '#c4a300'

export function makeId(chars) {
  var text = ""
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

  for (var i = 0; i < chars; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
    // text = text + possible.charAt(randomNumber(0,possible.length-1))
  }

  return text
}

// Merge two objects, with y taking priority over x
export function deepMerge(x,y) {
  function isObject(obj) {
    return obj === Object(obj);
  }

  let xKeys = Object.keys(x)
  let yKeys = Object.keys(y)
  let obj = {}

  for (var yKey of yKeys) {
    // Attributes in both arrays
    if (xKeys.includes(yKey)) {
      if (isObject(y[yKey]) && isObject(x[yKey]) && !Array.isArray(y[yKey])) {
        // For objects (except arrays) recursively call this function
        obj[yKey] = deepMerge(x[yKey],y[yKey])
      } else {
        // Else use the value from y
        obj[yKey] = y[yKey]
      }
    
    } else {
      // Only exists in y, add it
      obj[yKey] = y[yKey]
    }
  }

  // Find attributes that only exist in x and add them to obj
  for (var xKey of xKeys) {
    if (!yKeys.includes(xKey)) {
      obj[xKey] = x[xKey]
    }
  }

  return obj
}

export function getPosition(element) {
  var xPosition = 0
  var yPosition = 0

  while(element) {
      xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft)
      yPosition += (element.offsetTop - element.scrollTop + element.clientTop)
      element = element.offsetParent;
  }

  return { 
    x: xPosition, 
    y: yPosition,
    // top: element.getBoundingClientRect().top
  }
}

export function docObj(doc) {
  if (doc && !doc.data) {
    return doc
  } if (doc && doc.exists) {
    return Object.assign({},doc.data(),{id: doc.id, exists: true})
  } else if (doc) {
    return {id: doc.id, exists: false}
  } else {
    return {id: makeId(20), exists: false}
  }
}

export function mockDoc(data) {
  class MockDoc {
    constructor(myData) {
      this.myData = myData
      this.id = data.id

      
    }

    data() {
      return this.myData
    }
  }

  let doc = new MockDoc(data)

  return doc
}

export function saveObj(obj) {
  obj = Object.assign({}, obj)
  const keys = Object.keys(obj)
  if (keys.includes('id')) {
    delete obj.id
  }
  if (keys.includes('exists')) {
    delete obj.exists
  }
  return obj
} 

export function groupComments(comments, ornaments) {
  let groups = [] 
  let orphans = []
  if (comments && comments.length > 0 
    && ornaments && ornaments.length > 0) {
    // // There's at least one of each object
    // let ornamentIds = ornaments.map(o => o.id)
        // let annotationIds = annotations.map(a => a.id)
        orphans = comments
      .filter(a => !ornaments.some(o => o.id === a.id))
    
    function addGroups(ornament) {
      let comment = comments.find(a => a.id === ornament.id)
      groups.push({ornament: ornament, comment: comment})
    }

    for (var ornament of ornaments) {
      addGroups(ornament)
    }
  } else if (comments && comments.length > 0) {
    // There are only annotations, but no ornaments 
    orphans = comments
  } else if (ornaments && ornaments.length > 0) {
    // There are only ornaments, no annotations 
    groups = ornaments.map(o => {
      return {ornament: o, comment: null}
    })
  }

  return {
    groups: groups, 
    orphans: orphans
  }
}

export function iOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
}

export const defaultQuoteFormat = theme => {
  return {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    fontFamily: 'Cardo', //'Vollkorn',
    sourceFontFamily: 'Roboto',
    fontSize: 20,
    // fontSizeSm: 16,
    highlightBg: theme.palette.mode === 'light' ? yellow[100] : lime[900],
    highlightColor: theme.palette.text.primary,
    showHighlight: true,
    quotationMarks: false,
  }
}

export const annotationQuoteFormat = (annotation, theme, authorComment) => {
  // If the quote doesn't have any quotation marks add CSS quotes
  let objs = [defaultQuoteFormat(theme)]

  // if (!annotation.sentenceHTML.includes('"') 
  //   && !annotation.sentenceHTML.includes('“')) {
  //   objs.push({quotationMarks: true})
  // } 
  
  objs.push({quotationMarks: false})

  if (authorComment) {
    objs.push({background: theme.palette.background.default})
  }

  objs.push({fontSize: 18})

  return Object.assign({}, ...objs)
}

export const defaultHltColor = (theme, selection) => {
  if (!theme || theme.palette.mode === 'light') {
    if (selection) {
      return yellow[200]
    } else {
      return yellow[100]
    }
  } else if (theme.palette.mode === 'dark') {
    if (selection) {
      return lime[800]
    } else {
      return lime[900]
    }
  }
} 

export const authorHltColor = theme => {
  if (theme.palette.mode === 'light') {
    return '#ebdfc0'
  } else if (theme.palette.mode === 'dark') {
    return '#80714d'
  }
} 

export function rgbArr(str) {
  // let regex = /([0-9])\w+/g
  let regex = /(\d)+/g
  let matches = str.match(regex)
  if (matches.length === 3) {
    return [Number(matches[0]), Number(matches[1]), Number(matches[2])]
  } else {
    throw new Error('Unrecognized rgb string ' + str)
  }
}

export function rgbStr(arr) {
  return `rgb(${arr[0]}, ${arr[1]}, ${arr[2]})`
}

export function mergeColors(arr) {
  let colors = arr.map(i => rgbArr(i))
  let rgb = [
    Math.round(colors.reduce((a,b) => a + b[0], 0)/arr.length),
    Math.round(colors.reduce((a,b) => a + b[1], 0)/arr.length),
    Math.round(colors.reduce((a,b) => a + b[2], 0)/arr.length),
  ]
  return rgbStr(rgb)
}

export function stripedColors(arr) {
  let inc = 10
  let px = 0
  let str = 'repeating-linear-gradient(45deg,'
  // let colors = arr.map(i => rgbArr(i))

  for (var color of arr) {
    str = str + color + ' ' + (px > 0 ? px + 'px' : '') + ','
    px = px + inc
    str = str + color + ' ' + px + 'px,'
  }

  str = str.slice(0, -1) + ')'  

  return str
}

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   {number}  h       The hue
 * @param   {number}  s       The saturation
 * @param   {number}  l       The lightness
 * @return  {Array}           The RGB representation
 */
function hslToRgb(h, s, l){
    var r, g, b;

    if(s === 0){
        r = g = b = l; // achromatic
    }else{
        var hue2rgb = function hue2rgb(p, q, t){
            if(t < 0) t += 1;
            if(t > 1) t -= 1;
            if(t < 1/6) return p + (q - p) * 6 * t;
            if(t < 1/2) return q;
            if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
            return p;
        }

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = hue2rgb(p, q, h + 1/3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1/3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 * @param   {number}  r       The red color value
 * @param   {number}  g       The green color value
 * @param   {number}  b       The blue color value
 * @return  {Array}           The HSL representation
 */
function rgbToHsl(r, g, b){
    r = r / 255 
    g = g / 255 
    b = b / 255
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max){
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
            default: h = 0;
        }
        h /= 6;
    }

    return [h, s, l];
}

function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export function brightenColor(str) {
  if (str && str.charAt(0) === '#') {
    let rgbObj = hexToRgb(str)
    str = `rgb(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b})`
  }

  let rgb = rgbArr(str)
  let hsl = rgbToHsl(rgb[0], rgb[1], rgb[2])
  let lightHsl = [hsl[0], hsl[1], hsl[2] - 0.15]
  let lightRgb = hslToRgb(lightHsl[0], lightHsl[1], lightHsl[2])
  return rgbStr(lightRgb)
}

export function lightenColor(str) {
  if (str && str.charAt(0) === '#') {
    let rgbObj = hexToRgb(str)
    str = `rgb(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b})`
  }

  let rgb = rgbArr(str)
  let hsl = rgbToHsl(rgb[0], rgb[1], rgb[2])
  let lightHsl = [hsl[0], hsl[1], hsl[2] + 0.5]
  let lightRgb = hslToRgb(lightHsl[0], lightHsl[1], lightHsl[2])
  return rgbStr(lightRgb)
}

/* eslint-disable no-control-regex */
export const validateEmail = email => {
  const rgx = RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)
  return rgx.test(email)
}
/* eslint-enable no-control-regex */

export async function urlFromLocation(location, projectId) {
  return `https://storage.googleapis.com/${projectId}.appspot.com/${location}`
  // if (sessionStorage) {
  //   let url = sessionStorage.getItem(location)
  //   if (url) {
  //     return url
  //   }
  // }

  // try {
  //   let url = await storage.ref().child(location).getDownloadURL()
  //   sessionStorage && sessionStorage.setItem(location, url)
  //   return url
  // } catch (err) {
  //   let url = `${process.env.PUBLIC_URL}/images/image_removed.png`
  //   sessionStorage && sessionStorage.setItem(location, url)
  //   return url
  // }
}

export function urlOfLocation(location, projectId) {
  return `https://storage.googleapis.com/${projectId}.appspot.com/${location}`
}

const downloadImage = data => {
  var a = document.createElement('a')
  a.href = data
  a.download = "quote.png"
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
} 

function filterNodes(node) {
  if (node.className && typeof node.className === 'string') {
    if (node.className.includes('corner-button')) {
      return false
    }
    if (node.className.includes('scaling-div')) {
      return false
    }
    if (node.className.includes('cite-number')) {
      return false
    }
  }
  // return node.className ? !node.className.includes('corner-button') : true
  return true
}

export const saveImage = (element, scale) => {
  let rect = element.getBoundingClientRect()
  // let scale = matches ? 4 : 2
  return domtoimage.toPng(element, {
    filter: filterNodes,
    width: rect.width * scale,
    height: rect.height * scale,
    style: {
      transform: 'scale(' + scale + ')',
      transformOrigin: 'top left'
    },
  })
  .then(dataUrl => {
    if (dataUrl) {
      downloadImage(dataUrl)
      return true
    } else {
      return false
    }
  })
  .catch(error => {
    console.log('error',error)
    return false
  })
}  

export const indexName = name => {
  if (process.env.NODE_ENV === 'development') {
    return 'dev_' + name
  } else if (process.env.NODE_ENV === 'production') {
    return 'prod_' + name
  } 
}

export const createArticle = (context, navigate, audio = false) => {
  let data = {
    title: '',
    body: '',
    description: '',
    published: false,
    creator: {uid: context.authUser.uid},
    aliasId: context.currentAlias,
    timeStamp: context.timeStamp()
  }

  if (audio) {
    data['audio'] = {
      hasAudio: true
    }
  }

  context.db.collection('articles').add(data)
    .then((docRef) => {
        console.log("Document written with ID: ", docRef.id)
        // context.amplitude.getInstance().logEvent('create_article')
        context.analytics && context.analytics.logEvent('create_article')
        // Redirect to the article
        navigate('/editor/' + docRef.id)  
    })
    .catch((error) => {
        console.error("Error adding document: ", error);
    })
}

// Alias themes store the light and dark version of the palette in an array
// Takes an alias theme and returns a Material-ui compatible theme
export const aliasTheme = (obj, type) => {
  console.log('aliasTheme', obj)
  if (!obj || !obj.palette) return obj

  const theme = Object.assign({}, obj, {palette: obj.palette[type]})

  return theme
}

// Change a font size rem value
export const incRem = (str, increment) => {
  let val = Number(str.replace('rem',''))
  let newVal = val + increment
  return newVal + 'rem'
}

export const imageDimensions = async (file) => {
  return new Promise(resolve => {
    let img = new Image()
    img.src = window.URL.createObjectURL(file)
    img.onload = () => {
       // alert(img.width + " " + img.height);
       resolve({width: img.width, height: img.height})
    }
  })
}

export const dataURLToBlob = function(dataURL) {
    var BASE64_MARKER = ';base64,';
    var parts, contentType, raw
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
        parts = dataURL.split(',');
        contentType = parts[0].split(':')[1];
        raw = parts[1];

        return new Blob([raw], {type: contentType});
    }

    parts = dataURL.split(BASE64_MARKER);
    contentType = parts[0].split(':')[1];
    raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], {type: contentType});
}

// Get Google Book data from the API and 
export async function getGoogleBook(id, key) {
  let url = `https://www.googleapis.com/books/v1/volumes/${id}`

  // Adding the api key gave the error message "Service unavailable"
  // Docs say key is not needed for these requests
  // url += `&key=${key}`

  return fetch(url).then(res => {
    return res.json().then(json => {
      console.log('got google book', json)
      return json
    })
  }, error => {
    let result = {
      error: 'Book could not be fetched',
      message: error.message
    }

    return result
  })
}

// Re-format google book data so it matches our format 
export function googleBookToBt(record, boostTarget) {
  console.log('record', record)
  let r = {
    book: true,
    googleBookId: record.id,
    meta: {
      title: record.volumeInfo.title,
      date_published: record.volumeInfo.publishedDate,
      authors: record.volumeInfo.authors,
      industryIdentifiers: record.volumeInfo.industryIdentifiers
    },
    selfLink: record.volumeInfo.infoLink
  }

  if (record.volumeInfo.imageLinks?.smallThumbnail) {
    r['meta']['smallThumbnail'] = record.volumeInfo.imageLinks?.smallThumbnail
  }

  let bt = Object.assign({}, boostTarget, r)

  return bt
}

// Contains author names, but missing work ids
// https://openlibrary.org/api/books?bibkeys=ISBN:9781594133558&jscmd=data
async function fetchOLBook(isbn) {
  let url = `https://openlibrary.org/api/books?bibkeys=ISBN:${isbn}&format=json&jscmd=data`
  console.log('url',url)
  return fetch(url)
  .then(response => response.json())
  .then(data => {
    return data
  })
}

// Contains work ids but no author names
async function fetchOLRecord(key) {
  let url = `https://openlibrary.org${key}.json`
  console.log('url',url)
  return fetch(url)
  .then(response => response.json())
  .then(data => {
    return data
  })
  .catch(err => {
    console.log('failed');
  })
}

export async function getOLRecord(isbns, context) {
  const promises = []

  for (var i of isbns) {
    promises.push(fetchOLBook(i));
  }

  let results = await Promise.all(promises);

  console.log('results', results);

  let result = results.find(r => Object.keys(r).length > 0);
  // if (false) {
  if (result) {

    let olObj = result
    let olKey = Object.keys(olObj)[0]
    let olData = olObj[olKey]

    // Try to get the book from a different API that will give work_id 
    let olRecord
    try {
      olRecord = await fetchOLRecord(olData.key)
    } catch(err) {
      console.warn('could not get OL record for this isbn')
    }

    console.log('olRecord', olRecord)

    if (olRecord) {
      console.log('merging...')
      olData = merge(olData, olRecord)
    }

    // Normalize the data, same process as when uploading
    if (olData.authors) {
      olData.authorRecords = olData.authors
      olData.authorUrls = olData.authors.map(a => a.url).filter(a => a)
      olData.authors = olData.authors.map(a => a.name).filter(a => a)
    }

    let isbns = []
    for (var id of Object.keys(olData.identifiers)) {
      if (['isbn_10', 'isbn_13'].includes(id)) {
        isbns.concat(olData.identifiers[id])
      }
    }
    olData.isbns = isbns


    olData.work_ids = []
    for (var workObj of olData.works) {
      olData.work_ids.push(workObj.key.replace('/works/',''))
    }
    // If there were no work ids set this attribute to false
    // This allows querying for missing work ids
    if (olData.work_ids.length === 0) {
      olData.work_ids = false
    }

    return olData

    // // TODO Handle this differently so it can be used to replace 
    // // boost targets
    // let btDoc = context.db.collection('boost_targets').doc()

    // let btData = {
    //   boostTarget: {
    //     book: true,
    //     meta: olData,
    //     timeStamp: context.timeStamp()
    //   },
    //   boostTargetId: btDoc.id
    // }

    
    // await btDoc.set(btData.boostTarget)

    // // props.selectBook(btData)

    //     // // setSelecting(false)
    // // return
  } else { 
    return null
  }
}

export const arraysEqual = (a1, a2) => {
  if (!Array.isArray(a1) || !Array.isArray(a2)) {
    return false
  } else if (a1.length !== a2.length) {
    return false
  } else {
    return a1.every(item => a2.includes(item))
  }
}

export function removeEmpty(obj) {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => (v !== null && v !== ''))
      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
  );
}

export function isSubscriptionLocked(aliasData, context) {
  if (aliasData?.monetization) {
    if (context.aliases?.some(a => a.id === aliasData.id)) {
      // This is the user's alias
      return false
    } else if (aliasData.monetization.visibility === 'anyone') {
      return false
    } else if (aliasData.monetization.visibility === 'members') {
      return !context.hasStripeSubscription(
        aliasData.id
      )
    }
  } else {
    return false
  }
}

export function isNewAlias(alias) {
  return alias?.timeStamp?.seconds > (Date.now()/1000 - 60*60*24*7)
}

/*****************************
 * SANITIZE HTML RULES
 *
 * !!!Important!!! These are duplicated in functions/util.js
 * If they are changed here they should be changed there
 *****************************/

export const articleRules = {
  allowedTags: ['a', 'strong', 'em', 'p', 'div', 'h3', 'h2', 'h1', 'ul',
    'li', 'footnote'],
  allowedAttributes: {
    'a': [ 'href' ],
    'div': [ 'class', 'boosttargetid', 'src', 'location', 'caption',
      'photographer', 'photographerurl'],
    'footnote': ['class', 'annoid', 'type', 'value']
  }
}

export const bookRules = {
  allowedTags: ['strong', 'em', 'p', 'div', 'h3', 'h2', 'h1', 'ul',
    'li', 'footnote', 'a', 'table', 'td', 'tr'],
  allowedAttributes: {
    'a': [ 'href' ],
    'div': [ 'class', 'boosttargetid', 'src', 'location', 'caption',
      'photographer', 'photographerurl'],
    'footnote': ['class', 'annoid', 'type', 'value']
  }
}

export const quoteRules = {
  allowedTags: ['strong', 'em', 'p', 'div', 'span', 'br'],
  allowedAttributes: {
    'span': ['quote-highlight']
  }
}

export const commentRules = {
  allowedTags: ['p', 'div'],
  allowedAttributes: {
    'p': [ 'empty-node' ],
    'div': [ 'empty-node'],
  }
}

export const annotationRules = {
  allowedTags: ['a', 'strong', 'em', 'p', 'div', 'h3', 'h2', 'h1', 'ul', 
    'ol', 'li'],
  allowedAttributes: {
    'a': [ 'href' ],
    'div': [ 'class', 'boosttargetid', 'src', 'location', 'caption',
      'photographer', 'photographerurl', 'data-pm-slice'],
  }
}

export const emptyRules = {
  allowedTags: [],
  allowedAttributes: {}
}

/*********************
 * Article categories
 *********************/

export const categories = {
  culture: false,
  politics: false,
  technology: false,
  business: false,
  finance: false,
  'food & drink': false,
  sports: false,
  faith: false,
  news: false,
  music: false,
  literature: false,
  'art & illustration': false,
  science: false,
  health: false,
  philosophy: false,
  history: false,
  travel: false,
  education: false
}

export const slackUrl = 'https://join.slack.com/t/spiritedworld/shared_invite/zt-vmqt9xpy-UNRZdpcXFmx6cvWK7bco1Q'