import React, {
  useState,
  useEffect,
  Suspense
} from 'react'

import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  useParams,
  matchPath,
  useNavigationType 
} from 'react-router-dom'

import NavMenu from './NavMenu'
import OverlayDialog from './OverlayDialog'
import LoadingWrapper from './LoadingWrapper'
import Landing from '../app/Landing'
import NotFound from './NotFound'
import UnsafeLink from './UnsafeLink'
import EmailLoginHandler from './EmailLoginHandler.js'

import CssBaseline from '@mui/material/CssBaseline'
import { useTheme, ThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

import Head from './Head';

import '../index.css'
import "core-js/stable"
import "regenerator-runtime/runtime"

const Welcome = React.lazy(() => import('../app/Welcome'));
const Main = React.lazy(() => import('../lists/Main'));
const MyStuff = React.lazy(() => import('../mystuff/MyStuff'));
const AliasManager = React.lazy(() => import('../alias/AliasManager'));
const FAQ = React.lazy(() => import('./FAQ'));
const AdminConsole = React.lazy(() => import('../admin/AdminConsole'));
const EmailNotifications = React.lazy(() => import('./EmailNotifications'));
const Subscriptions = React.lazy(() => import('./Subscriptions'));
const Unsubscribe = React.lazy(() => import('./Unsubscribe'));
const Following = React.lazy(() => import('../lists/Following'));
const HashtagPage = React.lazy(() => import('../lists/HashtagPage'));
const SignIn = React.lazy(() => import('./SignIn'));
const Join = React.lazy(() => import('./Join'));
const Notifications = React.lazy(() => import('../notifications/Notifications'));
const ExtensionDownload = React.lazy(() => import('../extension/ExtensionDownload'));
const Support = React.lazy(() => import('../app/Support'));
const CommentPage = React.lazy(() => import('../comment/CommentPage'));
const BoostTargetPage = React.lazy(() => import('../boost_target/BoostTargetPage'));
const AliasCommentsPage = React.lazy(() => import('../boost_target/AliasCommentsPage'));
const AliasPage = React.lazy(() => import('../alias/AliasPage'));
const Browse = React.lazy(() => import('../lists/Browse'));
const Article = React.lazy(() => import('../article/Article'));
const Editor = React.lazy(() => import('../editor/Editor'));
const Library = React.lazy(() => import('../library/Library'));
const Reading = React.lazy(() => import('../library/Reading'));
const Shelf = React.lazy(() => import('../library/Shelf'));
const ShelfComments = React.lazy(() => import('../library/ShelfComments'));
const FollowersPage = React.lazy(() => import('../alias/FollowersPage'));
const FollowingPage = React.lazy(() => import('../alias/FollowingPage'));
const PrivacyPolicy = React.lazy(() => import('../app/PrivacyPolicy'));
const TermsOfService = React.lazy(() => import('../app/TermsOfService'));
const About = React.lazy(() => import('../app/About'));

const SignInMatch = props => {
  let params = useParams()
  return <SignIn signInId={params.signInId} />
}

const HashtagMatch = props => {
  let params = useParams()
  return <Suspense fallback={<Landing />}>
    <HashtagPage {...props} hashtag={params.hashtagId} />
  </Suspense>
}

export const routeMap = [
  {
    path: "/extension",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <ExtensionDownload {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/comment/:commentId",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <CommentPage {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/responses/:boostTargetId/:chapterId",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <BoostTargetPage {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/responses/:boostTargetId",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <BoostTargetPage {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/comments/:boostTargetId/:aliasId",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <AliasCommentsPage {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/identities", 
    component: props => {
      return <LoadingWrapper>
        <Suspense fallback={<Landing />}>
          <AliasManager {...props} />
        </Suspense>
      </LoadingWrapper>
    },
    action: 'system'
  },
  {
    path: "/archive",
    component: props => {
      return <LoadingWrapper>
        <Suspense fallback={<Landing />}>
          <MyStuff {...props} />
        </Suspense>
      </LoadingWrapper>
    },
    action: 'system'
  },
  {
    path: "/article/:articleId",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <Article {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/book/:bookId", // /:chapter?/:paragraph?",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <Article {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/unsafe-link",
    component: UnsafeLink,
    action: 'replace'
  },
  {
    path: "/not-found",
    component: NotFound,
    action: 'replace'
  },
  {
    path: "/editor/:articleId",
    component: props => {
      return <LoadingWrapper>
        <Suspense fallback={<Landing />}>
          <Editor {...props} />
        </Suspense>
      </LoadingWrapper>
    },
    action: 'base'
  },
  {
    path: "/faq",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <FAQ {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/admin",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <AdminConsole {...props} />
      </Suspense>
    },
    action: 'base'
  },
  {
    path: "/signin/:signInId",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <SignInMatch {...props}  />
      </Suspense>
    },
    action: 'base'
  },
  {
    path: "/hashtag/:hashtagId",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <HashtagPage {...props}  />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/browse/:btId",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <Browse {...props} />
      </Suspense>
    },
    action: 'system'
  },
  {
    path: "/browse",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <Browse {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/join",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <Join {...props} />
      </Suspense>
    },
    action: 'base'
  },
  {
    path: "/notifications",
    component:  props => {
      return <LoadingWrapper>
        <Suspense fallback={<Landing />}>
          <Notifications {...props} />
        </Suspense>
      </LoadingWrapper>
    },
    action: 'overlay'
  },
  {
    path: "/following",
    component: props => {
      return <LoadingWrapper>
        <Suspense fallback={<Landing />}>
          <Following {...props} />
        </Suspense>
      </LoadingWrapper>
    },
    action: 'system'
  },
  {
    path: "/email-notifications",
    component: props => {
      return <LoadingWrapper>
        <Suspense fallback={<Landing />}>
          <EmailNotifications {...props} />
        </Suspense>
      </LoadingWrapper>
    },
    action: 'system'
  },
  {
    path: "/subscriptions",
    component: props => {
      return <LoadingWrapper>
        <Suspense fallback={<Landing />}>
          <Subscriptions {...props} />
        </Suspense>
      </LoadingWrapper>
    },
    action: 'system'
  },
  {
    path: "/unsubscribe/:subId",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <Unsubscribe {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/contact",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <Support {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/support",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <Support {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/about",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <About {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/hashtag/:hashtagId",
    component: HashtagMatch,
    action: 'overlay'
  },
  // {
  //   path: "/library/all-books/:bookId",
  //   component: props => {
  //     return <Suspense fallback={<Landing />}>
  //         <AllBooks {...props} />
  //       </Suspense>
  //   },
  //   action: 'system'
  // },
  // {
  //   path: "/library/all-books",
  //   component: props => {
  //     return <Suspense fallback={<Landing />}>
  //         <AllBooks {...props} />
  //       </Suspense>
  //   },
  //   action: 'system'
  // },
  {
    path: "/library/reading",
    component: props => {
      return <LoadingWrapper>
        <Suspense fallback={<Landing />}>
          <Reading {...props} />
        </Suspense>
      </LoadingWrapper>
    },
    action: 'overlay'
  },
  {
    path: "/library/:shelfId/comments",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <ShelfComments {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/library/:shelfId",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <Shelf {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/library",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <Library {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/privacy",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <PrivacyPolicy {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/tos",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <TermsOfService {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/about",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <About {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/:aliasName/following",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <FollowingPage {...props}  />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/:aliasName/followers",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <FollowersPage {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/:aliasName/reading",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <AliasPage {...props} tab='reading' />
      </Suspense>
    },
    action: 'tab'
  },
  {
    path: "/:aliasName",
    component: props => {
      return <Suspense fallback={<Landing />}>
        <AliasPage {...props} />
      </Suspense>
    },
    action: 'overlay'
  },
  {
    path: "/",
    component: props => {
      return <Suspense fallback={<Welcome />}>
        <Main {...props} />
      </Suspense>
    },
    action: 'base'
  },
]

const WebAppRouter = props => {
  const location = useLocation();
  const params = useParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const navigationType = useNavigationType();

  /*************************
   * Component
   *************************/

  const [routeStack, setRouteStack] = useState([]);

  useEffect(() => {
    // Create an object containing location and match, needed for overlays
    let newRoute = routeMap.find(r => {
      return Boolean(matchPath(r, location.pathname));
    })

    let match = matchPath(newRoute, location.pathname);

    newRoute = Object.assign({}, newRoute, {
      location: Object.assign({}, location),
      match: match,
      params: params
    });

    setRouteStack(prevRouteStack => {
      if (navigationType === 'POP') {
        // POP triggers on back button click and when a page is loaded in 
        // an empty tab(?)
        if (prevRouteStack.length === 0) {
          // App loading for the first time
          return [newRoute]
        } else  {
          // Find the index of the new route in the stack.
          let idx = prevRouteStack.findIndex(
            r => r.location.pathname === newRoute.location.pathname
          )

          if (idx >= 0) {
            // Handle the case when they use the browser feature to go back
            // multiple pages
            let subtract = idx - (prevRouteStack.length-1)

            return prevRouteStack.slice(0, subtract)
          } else {
            // Could not find a page to go back to, make new route the base
            // page instead
            return [newRoute]
          }
        } 
      } else if (navigationType === 'PUSH') {
        // PUSH triggers when clicking a link
        if (prevRouteStack.length === 0) {
          return [newRoute]
        } else {
          if (newRoute.action === 'base') {
            // When going to a base route remove the stack and set this
            // route at the new base
            return [newRoute]
          } else if (newRoute.action === 'overlay') {
            // Overlay routes are added to the stack
            return prevRouteStack.concat(newRoute)
          } else if (newRoute.action === 'tab') {
            // The information from this route will be used to 
            // change the tab on the page. 
            return prevRouteStack;
          } else if (newRoute.action === 'system') {
            // System routes replace other system routes. Replacement is 
            // handled in LeftMenu.js. If a system route arrives as a PUSH
            // then it is added to the stack.
            return prevRouteStack.concat(newRoute)
          }
        }
      } else if (navigationType === 'REPLACE') {
        if (prevRouteStack.length === 0) {
          // This should never happen
          return [newRoute]
        } else {
          // A route set to REPLACE should replace the current top off the
          // stack 
          return prevRouteStack.slice(0, -1).concat(newRoute)
        } 
      }
    })
  },[location])

  // The switch is set to the first route in the stack
  const switchLocation = () => {
    if (routeStack?.length > 0) {
      return routeStack[0].location
    } else {
      return location
    }
  }

  const containerStyle = () => {
    if (matches && location.pathname === "/") {
      return {
        paddingLeft: 0,
        paddingRight: 0
      }
    } else {
      return {}
    }
  }

  return (
    <React.Fragment>
    <ThemeProvider theme={props.theme}>
      <Head  />
      <NavMenu />
      {/* <Welcome /> */}
      <CssBaseline />
      <Container 
        maxWidth='lg' 
        // className={classes.container}
        sx={containerStyle()}
      >
        <Box 
          mt={9}
        >
          <Routes location={switchLocation()}>
            {routeMap.map((obj, i) => {
              const MyComponent = obj.component

              return <Route 
                path={obj.path} 
                element={<MyComponent />}
                // component={obj.component} 
                // key={obj.path}
                key={i}
              />
            })}
          </Routes>
          {routeStack.map((route, i) => { 
            if (i === 0) return null

            return <OverlayDialog 
              // key={i}
              key={`${i}-${route.path}`}
              route={route}
              // handleBackClick={handleBackClick}
            />
          })}
        </Box>
      </Container>
      <EmailLoginHandler />
    </ThemeProvider>
    </React.Fragment>
  )
  
}

export default WebAppRouter