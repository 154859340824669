import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef
} from 'react'

import {
  useNavigate
} from 'react-router-dom'

import { DatabaseContext } from '../database.js'
import largeInputTheme from '../largeInputTheme.js'

import { getAuth, getMultiFactorResolver} from "firebase/auth";

import { useTheme, ThemeProvider } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'

// When a login link is used, show a loading dialog 
// If the email is not stored in local storage prompt for it
// Log the user in with href and email
const EmailLoginHandler = props => {
  const context = useContext(DatabaseContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const [email, setEmail] = useState('');
  // waiting, ready-to-sign-in, signing-in, email-required, mfa-required,
  // awaiting-new-user, finished
  const [status, setStatus] = useState('waiting');
  const [error, setError] = useState(null);
  const [continuePath, setContinuePath] = useState(null);

  const handleLogIn = event => {
    setError(null);
    setStatus('ready-to-sign-in');
  }

  const handleEmailChange = event => {
    setEmail(event.target.value);
  }

  const handleDialogClose = event => {
    // This will prevent the dialog from reopening
    setStatus('finished');
    navigate(continuePath, {
      replace: true
    })
  }

  const emailLinkSignIn = useCallback(email => {
    console.log('emailLinkSignIn was called');

    context.auth().signInWithEmailLink(email, window.location.href)
    .then(result => {
      console.log('login result', result)
      // Clear email from storage.
      window.localStorage.removeItem('emailForSignIn');
      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:
      // result.additionalUserInfo.isNewUser

      // TODO
      if (result.additionalUserInfo.isNewUser) {
        setStatus('awaiting-new-user');
      } 
    })
    .catch((error) => {
      // Some error occurred, you can inspect the code: error.code
      // Common errors could be invalid email and invalid or expired OTPs.
      if (error.code === 'auth/invalid-email') {
        setError('Invalid email address. Please enter the email you used to sign up.');
        setStatus('email-required');
        return;
      } else if (error.code === 'auth/multi-factor-auth-required') {
        const auth = getAuth();
        const resolver = getMultiFactorResolver(auth, error);
        setStatus('mfa-required'); 
        setResolver(resolver);
        sendCode(resolver);
        return;
      }
      console.log('emailLinkSignIn error', error.message);
      setError(error.message);
    });
  }, [context]);

  const [code, setCode] = useState('');
  const [resolver, setResolver] = useState(null);
  const [verifyingCode, setVerifyingCode] = useState(false); 
  const [verificationId, setVerificationId] = useState(null);
  
  const handleCodeChange = event => { 
    setCode(event.target.value);
  }

  const verifierRef = useRef();
  const dialogRef = useRef();

  // Send the user a code to verify their phone number
  const sendCode = async (resolver) => {
    console.log('will send SMS', resolver);

    const onSolvedRecaptcha = async () => { 
      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session
      };
  
      var phoneAuthProvider = new context.auth.PhoneAuthProvider();
      // Send SMS verification code.
      try {
        const verificationId = await phoneAuthProvider
        .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
        setVerificationId(verificationId);
        console.log('code sent');
      } catch (err) {
        setError(err.message);
        return;
      }
    }

    const recaptchaVerifier = new context.auth
    .RecaptchaVerifier(dialogRef.current, {
      'size': 'invisible',
      'callback': function(response) {
        // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
        onSolvedRecaptcha();
      },
    });

    verifierRef.current = recaptchaVerifier;

    recaptchaVerifier.render();
    recaptchaVerifier.verify();
  }

  const handleMFALogin = event => {
    setVerifyingCode(true);
    // Ask user for the verification code. Then:
    const cred = context.auth.PhoneAuthProvider
      .credential(verificationId, code);

    const multiFactorAssertion = context.auth.PhoneMultiFactorGenerator
      .assertion(cred);

    resolver.resolveSignIn(multiFactorAssertion)
    .then(userCredential => {
      // User successfully signed in with the second factor phone number.
      // TODO
      console.log('userCredential', userCredential);  
      setVerifyingCode(false);
      setStatus('finished');
    })
    .catch(error => {
      if (error.code === 'auth/invalid-verification-code') {
        setError('Invalid code.'); 
      } else {
        setError(error.message);
      }
      setVerifyingCode(false);
    });
  }

  // Open the log dialog when a sign in link is detected
  // Try to get the email address from local storage
  useEffect(() => {
    if (context.auth().isSignInWithEmailLink(window.location.href)
      && !context.user
      && status === 'waiting'
    ) {
      const urlParams = new URLSearchParams(window.location.search);
      const mfastep = urlParams.get('mfastep');
      const path = window.location.pathname 
        + (mfastep ? `?mfastep=${mfastep}` : '');

      setContinuePath(path);

      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        setStatus('email-required');
      } else {
        setEmail(email);
        setStatus('ready-to-sign-in');
      }
    } 
  }, [context, status]);

  useEffect(() => {
    if (status === 'ready-to-sign-in' && email !== '') {
      setStatus('signing-in');
      emailLinkSignIn(email);
    }
  },[status]);

  // After they have signed in wait for the 
  // user document to be created and load before closing the dialog
  useEffect(() => {
    if (context.user && ['signing-in', 'awaiting-new-user'].includes(status)) {
      navigate(continuePath, {
        replace: true
      })
      setStatus('finished');
    }
  },[context.user, status, navigate]);

  const inputTheme = largeInputTheme(theme);

  return <Dialog 
    open={!['waiting', 'finished'].includes(status)}
    onClose={handleDialogClose}
  >
    <div ref={dialogRef} />
    {['signing-in', 'awaiting-new-user'].includes(status) &&
      <DialogContent>
        <Grid 
          container 
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          <Grid item>
            <CircularProgress />
          </Grid>
          <Grid item>
            <Typography variant='body1'>
              {status === 'signing-in' && 'Signing in...'}
              {status === 'awaiting-new-user' && 'Creating your account...'}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    }
    {status === 'email-required' &&
      <React.Fragment>
        <DialogTitle>
          Email Required
        </DialogTitle>
        <DialogContent>
          <Grid 
            container 
            direction='column'
            alignItems='center'
            justifyContent='center'
          >
            <Grid item>
              <Typography variant='body1' gutterBottom>
                Please re-enter your email address
              </Typography>
            </Grid>
          </Grid>
          <TextField 
            onChange={handleEmailChange}
            value={email}
            fullWidth
            label='Email'
            type='email'
            variant='outlined'
          />
        </DialogContent>
        <DialogActions>
          <Button 
            variant='contained'
            onClick={handleLogIn}
          >
            Log in
          </Button>
        </DialogActions>
      </React.Fragment>
    }
    {status === 'mfa-required' &&
      <React.Fragment>
        <DialogTitle>
          Multi-Factor Authentication Required
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction='column'
            alignItems='center'
            justifyContent='center'
          >
            <Grid item>
              <Typography variant='body1' gutterBottom> 
                Please enter the code sent to your email address
              </Typography>
            </Grid>
            <Grid item>
              <ThemeProvider theme={inputTheme}>
                <TextField
                  fullWidth
                  label={!verifyingCode ? 'Code' : null}
                  variant='filled'
                  onChange={handleCodeChange} 
                  value={code}
                  disabled={verifyingCode}
                  InputProps={{
                    style: {
                      fontSize: 50
                    }
                  }}

                />
              </ThemeProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={handleMFALogin}
          >
            Log in
          </Button>
        </DialogActions>
      </React.Fragment> 
    }
    {error &&
      <Alert severity='error'>
        {error}
      </Alert>
    }
  </Dialog>
}

export default EmailLoginHandler;