import React, {
  useState,
  useEffect,
  useContext,
  Suspense
} from 'react'

import { useNavigate, useLocation, matchPath } 
  from 'react-router-dom'
import { DatabaseContext } from '../database.js'
import { urlFromLocation, createArticle } from '../util.js'

import bwTheme from './bwTheme'
import DialogTitle from './DialogTitle'
import EmailLinkSignIn from './EmailLinkSignIn'
import AudioPlayer from '../audio/AudioPlayer'
import LeftMenu from './LeftMenu'
import CloseButton from './CloseButton'
import PTIcon from '../icons/PTIcon'

import Snackbar from '@mui/material/Snackbar'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import SvgIcon from '@mui/material/SvgIcon'
import Badge from '@mui/material/Badge'
import Grid from '@mui/material/Grid'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

import MeetingRoom from '@mui/icons-material/MeetingRoom'
import HelpIcon from '@mui/icons-material/Help'
import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import NotificationsIcon from '@mui/icons-material/Notifications'
import CreateIcon from '@mui/icons-material/Create'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import HeadsetIcon from '@mui/icons-material/Headset'
import FeedbackIcon from '@mui/icons-material/Feedback'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'

import ExtensionIcon from '@mui/icons-material/Extension'
import MenuIcon from '@mui/icons-material/Menu';

import { ThemeProvider, useTheme } from '@mui/material/styles'

import useMediaQuery from '@mui/material/useMediaQuery'

const CommentEditor = React.lazy(() => import('../comment/CommentEditor'))

// NOTE export is at the bottom to include router
const NavMenu = (props) => {
  const context = useContext(DatabaseContext)
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [signInOpen, setSignInOpen] = useState(false)

  const routeTo = route => {
    setAnchorEl(null)
    navigate(route)
  }

  useEffect(() => {
    if (props.isOverlay) return

    setSignInOpen(old => {
      if (context.showSignIn) {
        return true
      } else {
        return false
      }
    })
  },[context.showSignIn, props.isOverlay])

  const handleSignInClose = () => {
    setSignInOpen(false)
  }

  const logOut = () => {
    context.auth().signOut().then(() => {
      context.clearStoredValues();
      context.setLastEmailLogin(null);
      console.log('Sign out successful');
    }).catch(function(error) {
      console.log('Sign out error: ');
      console.log(error);
    })
  }

  // Get the selected alias
  const [selectedAlias, setSelectedAlias] = useState({})

  const lookupAlias = () => {
    if (!(context.user || (context.aliases && context.aliases.length > 0))) return

    let selected = context.aliases
      .find(a => a.id === context.currentAlias)
    if (selected) {
      setSelectedAlias(selected)
    } else if (context.aliases.length > 0) {
      setSelectedAlias(context.aliases[0])
    } 
  }

  useEffect(lookupAlias,[context.user, context.aliases, context.currentAlias])

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [avatarImageURL, setAvatarImageURL] = useState(null)

  useEffect(() => {
    if (Object.keys(selectedAlias).length > 0) {
      if (selectedAlias.data().imageLocation) {
        urlFromLocation(selectedAlias.data().imageLocation, context.projectId)
        .then(url => {
          setAvatarImageURL(url)
        })
      } 
      // else {
      //    setAvatarImageURL(
      //     process.env.PUBLIC_URL + '/images/default_profile_picture.png'
      //   )
      // }
    }
  },[selectedAlias, context, props.isOverlay])

  const handleExtensionClick = event => {
    navigate('/extension')
    setAnchorEl(null)
  }

  const handlePaletteChange = () => {
    context.togglePalette()
    setAnchorEl(null)
  }

  /**************************
   * Drawer
   **************************/

  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = event => {
    setDrawerOpen(bool => !bool)
  }

  const requireDrawer = () => {
    return matches 
    || location.pathname.startsWith('/editor/')
    || location.pathname.startsWith('/article/')
    || location.pathname.startsWith('/archive')
    || location.pathname.startsWith('/book')
  }

  const logoClick = event => {
    if (requireDrawer()) {
      toggleDrawer(event);
    } else {
      routeTo('/')
    }
  }

  /**************************
   * Buttons
   **************************/

  // // AliasMenu optional items
  // let aliasOption = <MenuItem 
  //   key="myaliases"
  //   onClick={() => routeTo('/identities')} >
  //   <ListItemIcon>
  //     <PeopleIcon />
  //   </ListItemIcon>
  //   <Typography variant="inherit">
  //     Indentities
  //   </Typography>
  // </MenuItem>

  // let followingOption = <MenuItem 
  //   key="following"
  //   onClick={() => routeTo('/following')} >
  //   <ListItemIcon>
  //     <RecordVoiceIcon />
  //   </ListItemIcon>
  //   <Typography variant="inherit">
  //     Following
  //   </Typography>
  // </MenuItem>

  // let myStuffOption = <MenuItem 
  //   key="mystuff"
  //   onClick={() => routeTo('/mystuff')} >
  //   <ListItemIcon>
  //     <Folder />
  //   </ListItemIcon>
  //    <Typography variant="inherit">
  //     My Stuff
  //   </Typography>
  // </MenuItem>

  let helpOption = <MenuItem 
    key="mystuff"
    onClick={() => routeTo('/faq')} >
    <ListItemIcon>
      <HelpIcon />
    </ListItemIcon>
     <Typography variant="inherit">
      About PostText
    </Typography>
  </MenuItem>

  // let slackOption = <MenuItem 
  //   key="forum"
  //   onClick={handleSlackClick} >
  //   <ListItemIcon>
  //     <ForumIcon />
  //   </ListItemIcon>
  //    <Typography variant="inherit">
  //     Slack Channel
  //   </Typography>
  // </MenuItem>

  let extensionOption = <MenuItem 
    key="extension"
    onClick={handleExtensionClick} >
    <ListItemIcon>
      <ExtensionIcon />
    </ListItemIcon>
     <Typography variant="inherit">
      Extension
    </Typography>
  </MenuItem>

  // let forumOption = <MenuItem 
  //   key="forum"
  //   onClick={handleForumClick} >
  //   <ListItemIcon>
  //     <ForumIcon />
  //   </ListItemIcon>
  //    <Typography variant="inherit">
  //     Beta Forum
  //   </Typography>
  // </MenuItem>

  let logOutOption = <MenuItem 
      key="logout"
      onClick={() => logOut()} >
    <ListItemIcon>
      <MeetingRoom />
    </ListItemIcon>
     <Typography variant="inherit">
      Log Out
    </Typography>
  </MenuItem>

  // let archiveOption = <MenuItem 
  //     key="archive"
  //     onClick={() => routeTo('/archive')}
  //   >
  //   <ListItemIcon>
  //     <FolderIcon />
  //   </ListItemIcon>
  //    <Typography variant="inherit">
  //     Archive
  //   </Typography>
  // </MenuItem>

  let newArticleOption = <MenuItem 
      key="newArticle"
      onClick={() => {
        setAnchorEl(null)
        createArticle(context, navigate)
      }}
    >
    <ListItemIcon>
      <CreateIcon />
    </ListItemIcon>
     <Typography variant="inherit">
      Write Article
    </Typography>
  </MenuItem>

  let newPodcastOption = <MenuItem 
      key="newPodcast"
      onClick={() => {
        setAnchorEl(null)
        createArticle(context, navigate, true)
      }}
    >
    <ListItemIcon>
      <HeadsetIcon />
    </ListItemIcon>
     <Typography variant="inherit">
      New Podcast
    </Typography>
  </MenuItem>

  let paletteOption
  if (theme.palette.mode === 'light') {
    paletteOption = <MenuItem 
        key="palette"
        onClick={handlePaletteChange}
      >
      <ListItemIcon>
        <Brightness4Icon />
      </ListItemIcon>
       <Typography variant="inherit">
        Dark Mode
      </Typography>
    </MenuItem>
  } else if (theme.palette.mode === 'dark') {
    paletteOption = <MenuItem 
        key="palette"
        onClick={handlePaletteChange}
      >
      <ListItemIcon>
        <Brightness7Icon />
      </ListItemIcon>
       <Typography variant="inherit">
        Light Mode
      </Typography>
    </MenuItem>
  }

  // const boostsOption = <MenuItem 
  //     key="boosts"
  //     onClick={() => routeTo('/boosts')}
  //   >
  //   <ListItemIcon>
  //     <BoostIcon 
  //       sx={{
  //         ml: 1
  //       }} 
  //       htmlColor={gold}
  //     />
  //   </ListItemIcon>
  //    <Typography variant="inherit">
  //     Boosts
  //   </Typography>
  // </MenuItem>

  // let subscriptionsOption = <MenuItem 
  //     key="subscriptions"
  //     onClick={() => routeTo('/subscriptions')}
  //   >
  //   <ListItemIcon>
  //     <MailIcon />
  //   </ListItemIcon>
  //    <Typography variant="inherit">
  //     Subscriptions
  //   </Typography>
  // </MenuItem>

  let supportOption = <MenuItem 
      key="support"
      onClick={() => routeTo('/support')}
    >
    <ListItemIcon>
      <FeedbackIcon />
    </ListItemIcon>
     <Typography variant="inherit">
      Contact
    </Typography>
  </MenuItem>
 


  // let menuOptions = [aliasOption,myStuffOption,logOutOption]

  /************************
   * Alert Messages
   ************************/

  const [messageOpen, setMessageOpen] = useState(null)
  const [messageText, setMessageText] = useState('')
  const [messageButton, setMessageButton] = useState(null)

  const handleMessageClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setMessageOpen(false)
    setMessageText('')
    setMessageButton(null)
    context.removeAlert()
  }

  // Let context set alerts
  useEffect(() => {
    if (props.isOverlay) return

    if (context.alertMessage) {
      console.log(context.alertMessage)
      setMessageText(context.alertMessage.message)
      if (context.alertMessage.button) {
        setMessageButton(context.alertMessage.button)
      } 
      setMessageOpen(true)
    }
  },[context.alertMessage, props.isOverlay])

  /************************
   * Post Menu
   ************************/

  const [postOpen, setPostOpen] = useState(false)
  // const [postTarget, setPostTarget] = useState(null)
  const [postData, setPostData] = useState(null)

  const handlePostClose = () => {
    if (userInput) {
      setCancelOpen(true)
    } else {
      setPostOpen(false)
      context.closePost()
    }
  }

  const didPost = comment => {
    if (comment && location.pathname !== '/') {
      context.alert('Post added')
    }

    if (comment && postData.callback) {
      postData.callback(comment)
    }

    setPostOpen(false)
    setPostData(null)
  }

  const [cancelOpen, setCancelOpen] = useState(false)
  const [userInput, setUserInput] = useState(false)

  const handleCancelClose = event => {
    setCancelOpen(false)
  }

  const discard = event => {
    setCancelOpen(false)
    setPostOpen(false)
    context.closePost()
  }

  useEffect(() => {
    // The NavMenu is used on Overlays, this will cause multiple windows
    // to open if not blocked
    if (context.post && !props.isOverlay) {
      // setPostTarget(context.post.postTarget)
      setPostData(context.post)
      setPostOpen(true)
    } else {
      setPostOpen(false)
    }
  },[context.post, props.isOverlay])

  /************************
   * Redirect
   ************************/

  const redirect = () => {
    if (props.isOverlay) return
    
    if (context.redirect) navigate(context.redirect)
  }

  useEffect(redirect,[context.redirect, navigate, props.isOverlay])

  /************************
   * Boost Total
   ************************/

  // // hidden-boosts
  // const badgeValue = () => {
  //   return Math.floor(context.user.boosts - context.boostDecrements)
  // }

  /*************************
   * GDPR Box
   *************************/

  // const [showNotice, setShowNotice] = useState(true)

  // const gdprRef = useRef(null)

  // const goToPolicy = event => {
  //   event.preventDefault()
  //   navigate()
  // }

  // const handleNoticeClose = () => {
  //   setShowNotice(false)
  // }

  // const acceptPolicy = () => {
  //   console.log('accept')
  //   handleNoticeClose()
  // }

  // useEffect(() => {
  //   if (gdprRef.current && !notice) {
  //     const notice = new SimpleGDPR({
  //       theme: 'material'
  //     })
  //     setNotice(notice)
  //     if (matches) {
  //       notice.appendTo()
  //     }
  //   }
  // },[gdprRef.current])

  // const unreadNotifications = () => {
  //   return context.accountNotes.map(n => n.read === false).length
  // }

  // const callTest = async (event) => {
  //   const test = context.functions.httpsCallable('scheduledRSSFetch')
  //   await test()
  // }

  /*************************
   * Audio
   *************************/

  const showAudio = () => {
    return context.audioEl && context.audioLoaded && context.audioRoute 
      && !location.pathname.includes('/article/')
  }

  /*************************
   * Books
   *************************/

  const showChapterSelect = () => {
    let r = matchPath({
      path: "/book/:bookId"
    }, location.pathname)

    if (r && r.params?.bookId) {
      let obj = context.chapterMenus.find(m => m.bookId === r.params.bookId)
      if (obj) {
        return obj.select
      }
    }
  }

  // const showLogin = () => {
  //   return (!context.user && !context.authLoading 
  //     && (location.pathname.includes('/article') || matches))
  // }

  return (
    <div>
      <ThemeProvider theme={bwTheme(theme.palette.mode)}>
        <AppBar 
          position="fixed" 
          // className={classes.bar} 
          sx={{
            boxShadow: 'none',
            borderBottom: `1px solid ${theme.palette.divider}`
          }}
          color='primary'
        >
          <Toolbar>
            <Grid 
              container 
              direction='row' 
              wrap='nowrap'
              justifyContent='space-between'
              alignItems='center'
              // spacing={matches ? 2 : 10}
            >
              <Grid 
                item 
                container 
                alignItems='center' 
                justifyContent='flex-start'
                direction='row'
                spacing={matches ? 2 : 8}
                wrap='nowrap'
              >
                <Grid item>
                  {requireDrawer() ? (
                    <IconButton 
                      onClick={logoClick}
                    >
                      <MenuIcon />
                    </IconButton>
                  ):(
                    <SvgIcon 
                      fontSize='large' 
                      // className={classes.logo} 
                      sx={{
                        // objectFit: 'contain',
                        // marginRight: props => props.closeButton ? theme.spacing(4) : 'auto',
                        cursor: 'pointer',
                      }}
                      onClick={logoClick}
                    >
                      <PTIcon />
                      {/* <ScaledLogo scale={0.5} /> */}
                    </SvgIcon>
                  )}
                </Grid>

                {props.closeButton &&
                  <Grid item>
                    <IconButton 
                      // className={classes.backButton}
                      // sx={{
                      //   marginRight: 'auto'
                      // }}
                      edge="start" 
                      color="inherit" 
                      onClick={event => props.closeButton()} 
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Grid>
                }
              </Grid>
              <Grid item container justifyContent='center'>
                <Grid item>
                  {showChapterSelect() &&
                    <React.Fragment>
                      {showChapterSelect()}
                    </React.Fragment>
                  }
                  {showAudio() &&
                    <AudioPlayer
                      inNav={true}
                    />
                  }
                </Grid>
              </Grid>
              <Grid item container 
                justifyContent='flex-end' 
                alignItems='center' 
                // xs='auto'
              >
                {context.user &&
                  <React.Fragment>
                    <IconButton
                      onClick={() => routeTo('/notifications')}
                      color="inherit">
                      <Badge 
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        badgeContent={context.user.unreadNotifications} 
                        color='secondary'>
                        <NotificationsIcon />
                      </Badge>
                    </IconButton>
                  </React.Fragment>
                }
                {/*context.user && !matches &&
                  <Chip
                    onClick={() => routeTo('/boosts')}
                    label={badgeValue()}
                    icon={<BoostIcon 
                      sx={{
                        // color: 'white',
                        // fill: gold,
                        ml: 1
                      }} 
                      htmlColor={gold}
                    />
                    }
                    variant='outlined' 
                  />
                */}
                {(context.user && !context.authLoading) &&
                  <React.Fragment>
                    <IconButton onClick={handleClick}>
                      <Avatar 
                        src={avatarImageURL}   
                        sx={{
                          width: 32,
                          height: 32
                        }}
                      />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {context.user && context.user.rights 
                        && context.user.rights.adminConsole && 
                        <MenuItem 
                          key="admin"
                          onClick={() => routeTo('/admin')} 
                        >
                          <ListItemIcon>
                            <SupervisorAccountIcon />
                          </ListItemIcon>
                           <Typography variant="inherit">
                            Admin
                          </Typography>
                        </MenuItem>
                      }
                      {/* matches && boostsOption */}
                      {newArticleOption}
                      {/* newPodcastOption*/}
                      {/*
                      <Divider />
                      {archiveOption}
                      {aliasOption}
                      {followingOption}
                      {subscriptionsOption}
                      */}
                      <Divider />
                      {extensionOption}
                      {supportOption}
                      {paletteOption}
                      {/* {helpOption} */}
                      <Divider />
                      {logOutOption}
                    </Menu>
                  </React.Fragment>
                }
                {/* {(!context.user && !context.authLoading) && 
                  <React.Fragment>
                    <IconButton onClick={() => routeTo('/faq')}>
                      <HelpIcon />
                    </IconButton>
                    {
                    <IconButton onClick={handlePaletteChange}>
                      {theme.palette.mode === 'light' ? (
                        <Brightness4Icon />
                      ):(
                        <Brightness7Icon />
                      )}
                    </IconButton>
                    }
                  </React.Fragment>
                } */}
                {(!context.user && !context.authLoading) &&
                  <ThemeProvider theme={theme}>
                    {!matches &&
                      <Button
                        onClick={
                          event => context.promptSignIn('Sign Up')
                        }
                        variant="contained"
                        color='primary'
                        style={{
                          marginRight: '5px'
                        }}>
                        Sign Up
                      </Button>
                    }
                    <Button
                      onClick={
                        event => context.promptSignIn(
                          'Log In',
                          "Enter your email address to access your account. ")
                      }
                      color='primary'
                      variant="outlined"
                    >  
                      Log&nbsp;In
                    </Button>
                  </ThemeProvider>
                }
                {context.authLoading && 
                  <CircularProgress color="secondary" />
                }
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={messageOpen}
        autoHideDuration={3000}
        onClose={handleMessageClose}
        message={messageText}
        action={
          <React.Fragment>
            {messageButton ? (
              <React.Fragment>
                {messageButton}
              </React.Fragment>
            ) : (
              <IconButton 
                size="small" 
                color="inherit" 
                onClick={handleMessageClose}>
                <CloseIcon 
                  fontSize="small" />
              </IconButton>
            )} 
          </React.Fragment>
        }
      />
      {/*
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={showNotice}
        onClose={handleNoticeClose}
        message="This site uses cookies"
        action={<React.Fragment>
            <Link 
              color='secondary'
              href="/" 
              onClick={goToPolicy}>
              Learn more
            </Link>
            &nbsp;&nbsp;
            <Button color="primary" size="small" onClick={handleNoticeClose}>
              Accept
            </Button>
          </React.Fragment>
        }
      />
      */}
      <Dialog
        fullScreen={matches}
        open={postOpen}
        onClose={handlePostClose}
        maxWidth='xs'
        fullWidth
        PaperProps={{style: {overflowY: 'visible'}}}>
        <DialogTitle onClose={handlePostClose}>
          Create Post
        </DialogTitle>
        <DialogContent style={{ overflowY: 'visible' }}>
          {/*postData?.commentSummary &&
            <React.Fragment>
              {postData?.commentSummary}
              <Divider />
            </React.Fragment>
          */}
          <Suspense fallback={<div>Loading...</div>}>
            <CommentEditor 
              doneEditing={didPost}
              boostTarget={postData?.boostTarget}
              reply={postData?.reply}
              repost={postData?.repost}
              quotes={postData?.quotes}
              setPostTarget={() => console.log('setposttarget')}
              targetType='feed'
              post={true} 
              articleComment={postData?.articleComment}
              setUserInput={setUserInput}
            />
          </Suspense>
        </DialogContent>
      </Dialog>
      <Dialog
        open={Boolean(signInOpen)}
        onClose={handleSignInClose}
        // onExited={context.clearPrompt}
        TransitionProps={{onExited: context.clearPrompt}}
        maxWidth='xs'
        >
        <EmailLinkSignIn 
          close={handleSignInClose} 
        />
      </Dialog>
      <Dialog
        open={cancelOpen}
        onClose={handleCancelClose}
        fullScreen={matches}
        maxWidth='xs'
        // PaperProps={{style: {overflowY: 'visible'}}}
      >
        <DialogContent>
          <Typography variant='h6'>
            Discard changes?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={discard}>
            Discard
          </Button>
          <Button onClick={event => setCancelOpen(false)}>
            Keep Editing
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer 
        anchor='left'
        open={drawerOpen}
        onClose={toggleDrawer}
        PaperProps={{style: {
          borderRight: `2px solid ${theme.palette.divider}`,
          background: theme.palette.background.paper
        }}}

      >
        <Box sx={{
            margin: 2,
          }}
        >
          <CloseButton
            onClose={toggleDrawer}
          />
          <LeftMenu 
            toggleDrawer={toggleDrawer}
          />
        </Box>
      </Drawer>
    </div>
  )
}

export default NavMenu